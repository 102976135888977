import { PlayingCard, Deck, CardName } from "./Cards"

export type SelectedCard = {
    card: PlayingCard,
    selected: boolean
}

// A collection of cards smaller than or equal to a deck, with number and the actual cards included.
export type CardSet = {
    player: string,
    deck: Deck
}

export const nullCardSet:CardSet = { player: "", deck: new Deck() }

// A player, with their unique identifier (defined by them) and their hand
export type Player = {
    playerId: string,
    hand: CardSet,
    ready: boolean
}

export const nullPlayer:Player = { playerId:"", hand: nullCardSet, ready:false }

export const nullPlayerSet:Player[] = Array(8).fill(nullPlayer)

export type RoundInfo = {
    roundValue: CardName,
    startPlayer: Player,
    passNum: number
}

export const nullRoundInfo:RoundInfo = { roundValue: CardName.Joker, startPlayer:nullPlayer, passNum: -1 }

export type RoomAndPlayerUsername = {
    room: Room
    username: string
}

// The gameroom, which acts as a the lobby and the room itself. This consists of the Players in the room,
// the gameID (which allows to players to originally join), hasStarted (to check if the game actually began),
// lastPlayedCardSet (allowing for easy checks when calling bluff), and the order (which will be programmatically
// decided when the cards are dealt) 
export type Room = {
    players: Player[],
    gameId: string,
    bluffStatus: string,
    hasStarted: boolean,
    bluffCaller: Player,
    cardSetFromRound: CardSet[],
    cardsInPlay: Deck,
    roundInfo: RoundInfo,
    order: Player[],
    host: Player,
    // added this field to show hands after bluffs exclusively
    showCardSetFromRound: boolean,
    cardSetFromLastRound: CardSet[]
}

// bluffstatus can be 1, 0, -1, for bluffcaller being wrong, no bluff, and last cardplayer being wrong (lying)
export const nullRoom:Room = {
    players: [],
    gameId:"",
    bluffStatus: "",
    hasStarted:false,
    bluffCaller: nullPlayer,
    cardsInPlay: new Deck(),
    cardSetFromRound: [],
    roundInfo: nullRoundInfo,
    order: [],
    host: nullPlayer,
    showCardSetFromRound: false,
    cardSetFromLastRound: []
}

export type HistoryState = {
    [index: string]:any
}