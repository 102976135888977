import * as React from "react";
import {isOnMobile} from "./imageUtils";

function Svg3OfHearts(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <defs>
        <linearGradient id="3_of_hearts_svg__a">
          <stop offset={0} />
          <stop offset={1} stopOpacity={0.649} />
        </linearGradient>
        <linearGradient id="3_of_hearts_svg__b">
          <stop offset={0} stopColor="#fff" stopOpacity={0.435} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_hearts_svg__c">
          <stop offset={0} stopColor="#fff" stopOpacity={0.489} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_hearts_svg__d">
          <stop offset={0} stopColor="#fff" stopOpacity={0.237} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_hearts_svg__e">
          <stop offset={0} stopColor="#df0000" />
          <stop offset={1} stopColor="#df0000" stopOpacity={0.672} />
        </linearGradient>
        <linearGradient id="3_of_hearts_svg__f">
          <stop offset={0} stopColor="#fff" stopOpacity={0.313} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_hearts_svg__g">
          <stop offset={0} stopColor="#fff" stopOpacity={0.29} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
      </defs>
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={8.775}
        y={28.013}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fill="#df0000"
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={8.775}
          y={28.013}

          fontFamily="Arial"
        >
          {"3"}
        </tspan>
      </text>
      <path
        d="M23.332 32.282c-5.43 0-6.154 5.449-6.154 5.449s-.725-5.449-6.155-5.449c-3.8 0-7.24 2.443-7.24 7.052 0 6.09 11.016 12.213 13.395 21.154 2.263-8.934 13.394-15.065 13.394-21.154 0-4.609-3.44-7.052-7.24-7.052z"
        fill="#df0000"
      />
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-158.818}
        y={-213.515}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fill="#df0000"
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-158.818}
          y={-213.515}

          fontFamily="Arial"
        >
          {"3"}
        </tspan>
      </text>
      <path
        d="M144.001 209.246c5.43 0 6.155-5.448 6.155-5.448s.725 5.448 6.155 5.448c3.8 0 7.24-2.443 7.24-7.051 0-6.09-11.016-12.213-13.395-21.155-2.264 8.934-13.394 15.065-13.394 21.155 0 4.608 3.439 7.051 7.24 7.051zM93.528 24.196c-9.012 0-10.216 9.043-10.216 9.043s-1.203-9.043-10.215-9.043c-6.309 0-12.017 4.055-12.017 11.704 0 10.107 18.284 20.27 22.232 35.112 3.758-14.829 22.232-25.005 22.232-35.112 0-7.65-5.708-11.704-12.016-11.704zM73.097 215.414c9.012 0 10.215-9.043 10.215-9.043s1.204 9.043 10.216 9.043c6.308 0 12.016-4.055 12.016-11.704 0-10.107-18.283-20.27-22.232-35.112-3.757 14.829-22.232 25.005-22.232 35.112 0 7.65 5.708 11.704 12.017 11.704zM93.528 95.497c-9.012 0-10.216 9.043-10.216 9.043s-1.203-9.043-10.215-9.043c-6.309 0-12.017 4.054-12.017 11.704 0 10.107 18.284 20.27 22.232 35.112 3.758-14.83 22.232-25.005 22.232-35.112 0-7.65-5.708-11.704-12.016-11.704z"
        fill="#df0000"
      />
    </svg>
  );
}

export default Svg3OfHearts;
