import React from 'react'
import { ValueType } from 'react-select'
import { toast } from 'react-toastify'
import isObjEqual from 'fast-deep-equal'
import { CardName, Deck, PlayingCard, Suit } from "../../Cards"
import { CardRepresentation } from "../../Images/images"
import { CardSet, nullRoom, Player, Room } from '../../Types'
import { callBluff, finishRound, getLastPlayedCardSet, getLastPlayedCardSetWithCards, initRoom, moveCards, moveCardsWithinRoom, processNextTurn, getRoomOn } from '../utils'
import { PlayFunction } from 'use-sound/dist/types'

export type OptionType = {
    value: string,
    label: string
}

export const cardOptions:OptionType[] = [
    {value: 'Ace', label: 'Ace'},
    {value: 'Two', label: 'Two'},
    {value: 'Three', label: 'Three' },
    {value: 'Four', label: 'Four'},
    {value: 'Five', label: 'Five'},
    {value: 'Six', label: 'Six'},
    {value: 'Seven', label: 'Seven'},
    {value: 'Eight', label: 'Eight'},
    {value: 'Nine', label: 'Nine'},
    {value: 'Ten', label: 'Ten'},
    {value: 'Jack', label: 'Jack'},
    {value: 'Queen', label: 'Queen'},
    {value: 'King', label: 'King'}
]

export const allCardOptions:OptionType[] = [
    {value: 'Ace', label: 'Ace'},
    {value: 'Two', label: 'Two'},
    {value: 'Three', label: 'Three' },
    {value: 'Four', label: 'Four'},
    {value: 'Five', label: 'Five'},
    {value: 'Six', label: 'Six'},
    {value: 'Seven', label: 'Seven'},
    {value: 'Eight', label: 'Eight'},
    {value: 'Nine', label: 'Nine'},
    {value: 'Ten', label: 'Ten'},
    {value: 'Jack', label: 'Jack'},
    {value: 'Queen', label: 'Queen'},
    {value: 'King', label: 'King'},
    {value: 'Joker', label: 'Select a Card Value to Play'}
]

export const getInfoFromDatabase = (
    gameId:string,
    room:Room,
    player:Player,
    username:string,
    currentPlayerId:string,
    setCurrentPlayerId:React.Dispatch<React.SetStateAction<string>>,
    showTurnToast:boolean,
    setShowTurnToast:React.Dispatch<React.SetStateAction<boolean>>,
    setRoom:React.Dispatch<React.SetStateAction<Room>>,
    showBluffToast:boolean,
    setShowBluffToast:React.Dispatch<React.SetStateAction<boolean>>,
    setWinner:React.Dispatch<React.SetStateAction<string>>,
    setRoundVal:React.Dispatch<React.SetStateAction<ValueType<OptionType>>>,
    setControlBluffModalIsOpen:React.Dispatch<React.SetStateAction<boolean>>,
    setIsTurn:React.Dispatch<React.SetStateAction<boolean>>,
    setCards:React.Dispatch<React.SetStateAction<PlayingCard[]>>,
    setSelectedCards:React.Dispatch<React.SetStateAction<boolean[]>>,
    playSomeonePlayedSound:PlayFunction,
    playIsTurnNotification:PlayFunction
) => {
    getRoomOn(gameId, (rm:firebase.database.DataSnapshot) => {
        const newRoom = initRoom(rm, gameId)
        if (!isObjEqual(room, newRoom)) {
            setRoom(newRoom)
            return true
        }
        return false
    })
    .then((didRoomChange) => {
        if (player !== undefined && didRoomChange) {
            if (!room.showCardSetFromRound) {
                setControlBluffModalIsOpen(false)
            }
            const cardArr = player.hand.deck.getCards()
            const selCardArr = []
            for (let i = 0; i < player.hand.deck.getCount(); i++) {
                selCardArr.push(false)
            }
            // Should not only check at the beginning of a round
            // (i + 2) % room.order.length === 0
            for (let i = 0; i < room.order.length; i++) {
                if (room.order[i].hand.deck.getCount() === 0 && 
                    ((i + 2) % room.order.length === 0 || room.cardsInPlay.getCount() === 0)
                    ) {
                    setWinner(room.order[i].playerId)
                    break
                }
            }
            
            if (showBluffToast && room.bluffStatus !== '') {
                setShowBluffToast(false)
                if (!toast.isActive("bluff")) {
                    toast.info(room.bluffStatus, {
                        toastId: "bluff", 
                        autoClose: 11000,
                        onClose: ()=>{
                            setShowBluffToast(true)
                        },
                    })
                }
            }
            if (room.roundInfo.roundValue !== CardName.Joker) {
                setRoundVal({value: CardName[room.roundInfo.roundValue], label: CardName[room.roundInfo.roundValue]})
            } else {
                setRoundVal({value: 'Joker', label:'Joker'})
            }
            if (room.order.length > 0 && room.order[0].playerId === username) {
                setIsTurn(true)
                setCurrentPlayerId(room.order[0].playerId)
                if (!showTurnToast) {
                    setShowTurnToast(true)
                    if (!toast.isActive("newTurn")) {
                        toast.info("It's your turn!", {
                            toastId: "newTurn", 
                            autoClose: 3000,
                            onOpen: playIsTurnNotification
                        })
                    }
                }
            } else if (room.order.length > 0) {
                setIsTurn(false)
                setShowTurnToast(false)
                if (room.order[0].playerId !== currentPlayerId) {
                    playSomeonePlayedSound()
                    setCurrentPlayerId(room.order[0].playerId)
                }
            }
            setCards(cardArr)
            setSelectedCards(selCardArr)
        }
        
    })
}

// Used to get the CardName from the Select UI component, which is String based
export const getCardNameFromSelect = (selectedOption:any):CardName => {
    switch(selectedOption.label) {
        case "Ace":
            return CardName.Ace
        case "Two":
            return CardName.Two
        case "Three":
            return CardName.Three
        case "Four":
            return CardName.Four
        case "Five":
            return CardName.Five
        case "Six":
            return CardName.Six
        case "Seven":
            return CardName.Seven
        case "Eight":
            return CardName.Eight
        case "Nine":
            return CardName.Nine
        case "Ten":
            return CardName.Ten
        case "Jack":
            return CardName.Jack
        case "Queen":
            return CardName.Queen
        case "King":
            return CardName.King
        default:
            return CardName.Joker
    }
}

export const isAnyCardSelected = (selectedCards:boolean[]) => {
    for (let selectedCardIndex = 0; selectedCardIndex < selectedCards.length; selectedCardIndex++) {
        if (selectedCards[selectedCardIndex]) {
            return true
        }
    }
    return false
}

export const findNumCardsSelected = (selectedCards:boolean[]) => {
    let count = 0;
    for (let selectedCardIndex = 0; selectedCardIndex < selectedCards.length; selectedCardIndex++) {
        if (selectedCards[selectedCardIndex]) {
            count += 1
        }
    }
    return count
}

/**
 * May want to make a large-scale object that contains all the relevant state variables from
 * Game and import that everywhere (would clean up function signatures)
 */

// Provides the information for showing how many cards opponents have
export const showOpponentHands = (room:Room, username:string) => {
    let message = []
    for (let playerIndex = 0; playerIndex < room.order.length; playerIndex++) {
        const player = room.order[playerIndex]
        message.push(
            <div>
                {`${playerIndex + 1}: ${player.playerId} ${player.playerId === username ? "(you)" : ""} has ${player.hand.deck.getCount()} card${player.hand.deck.getCount() > 1 ? "s": ""}.`}
            </div>
        )
    }
    return message
}

// Provides the visual information to show that a card has been selected
export const changeBorder = (htmlElem: HTMLElement | null, selected:boolean) => {
    if (htmlElem != null) {
        if (selected) {
            htmlElem.style.outline = '5px solid yellow'
            return 1
        } else {
            htmlElem.style.outline = ''
            return -1
        }
    }
    return 0
}

// Creates an map of cardnames to JSX elements containing what needs to be rendered
export const groupCards = (
    cards:PlayingCard[], 
    selectedCards:boolean[], 
    setCards:(value: React.SetStateAction<PlayingCard[]>) => void, 
    setSelectedCards:(value: React.SetStateAction<boolean[]>) => void,
    numCardsSelected:number,
    setNumCardsSelected:React.Dispatch<React.SetStateAction<number>>
) => {
    let groupedCards: { [cardtype: number]: JSX.Element[]; } = {}
    for (let cardIndex = 0; cardIndex < cards.length; cardIndex++) {
        let entry = groupedCards[cards[cardIndex].cardName]
        if (entry === undefined) {
            entry = []
        }
        entry.push(
            <span
                id={`card${cardIndex.toString()}`}
                onClick={() => {
                    selectedCards[cardIndex] = !selectedCards[cardIndex]
                    setCards(cards)
                    setSelectedCards(selectedCards)
                    const changeNumSelectedCards = changeBorder(document.getElementById(`card${cardIndex.toString()}`), selectedCards[cardIndex])
                    setNumCardsSelected(numCardsSelected + changeNumSelectedCards)
                }}
                style={{
                    outline: `${selectedCards[cardIndex] ? '5px solid yellow' : ''}`,
                    margin: 1,
                    flexGrow: 1
                }}
                >
                {CardRepresentation(cards[cardIndex].cardName, cards[cardIndex].suit)}
            </span>
        )
        groupedCards[cards[cardIndex].cardName] = entry
    }
    return groupedCards
}

// OnClick methods

// Because of toast issues, playOnClick isn't defined here. Fix should come later to address
// these concerns

export const bluffOnClick = (
    room:Room, 
    player:Player | undefined, 
    gameId:string, 
    username:string,
    setRoom:React.Dispatch<React.SetStateAction<Room>>,
    setBluffCalled:(value: React.SetStateAction<boolean>) => void
) => {
    let initNum = 0
    let lastPlayer = ""
    if (player === undefined) {
        return new Promise(()=>{return {room: nullRoom, message: ""}})
    } else {
        return callBluff(room.gameId, player).then(success => {
            if (!success) {
                // beaten to the punch by another player
                return nullRoom
            } else {
                room.bluffCaller = player
                const lastPlayedCardSet = getLastPlayedCardSetWithCards(room)
                initNum = player.hand.deck.getCount()
                lastPlayer = lastPlayedCardSet.player
                setBluffCalled(true)
                // render update to page here (informing of the calling of bluff)
                // move cards and wait concurrently before explaining what happened
                // and continuing
                return moveCardsWithinRoom(room)
            }
        }).then((newRoom) => {
            if (isObjEqual(nullRoom, newRoom)) {
                return {room: nullRoom, message: ""}
            }
            // at this stage, the bluffcaller IS the current player
            let num = 0
            for (let i = 0; i < newRoom.order.length; i++) {
                if (newRoom.order[i].playerId === username) {
                    num = newRoom.order[i].hand.deck.getCount()
                    break
                }
            }
            let message = ""
            const truthMessages = [
                "Looks like you were wrong this time. Want to show everyone the deck or take your shame in silence?",
                "You should really be more trusting! They didn't lie this time. Want to show the deck so everyone can have a laugh?",
                "Oh dear, you seem quite paranoid. Not everyone lies. Want to show everyone what was played or keep it a secret?",
                "You know you could have passed, right? You didn't HAVE to call bluff. Want to show the deck to all so we can all learn from this lesson?"
            ]
            const lieMessages = [
                "Looks like you were right to doubt! Want to show everyone the deck so they can all see the lies?",
                "That sneaky player! Good on you to call them on their BS (Brussel Sprouts). Want to show everyone the deck to show all how wrong they are?",
                "Does it hurt? To have such an insight into your opponent's psyche that you can FEEL the lies? Additionally, do you want to show the deck to show how good you are at figuring it out?",
                "Sounds like people should call you the \"Oracle of our Time\" as you seem to have nailed the liar. Want to show the deck to shame them?"
            ]
            if (num > initNum) {
                // truth was told
                newRoom.bluffStatus = `A bluff has been called! ${newRoom.bluffCaller.playerId} was forced to pick up the deck! They should really be more trustworthy; ${lastPlayer} didn't lie (this time).`
                message = truthMessages[Math.floor(Math.random() * truthMessages.length)]
            } else {
                // lies were told
                newRoom.bluffStatus = `A bluff has been called! ${lastPlayer} was forced to pick up! ${newRoom.bluffCaller.playerId} was right to doubt!`
                message = lieMessages[Math.floor(Math.random() * lieMessages.length)]
            }
            return {room: newRoom, message: message}
        })
    }   
}

// Render related Methods

export const renderBluffCards = (cardsets:CardSet[]) => {
    let retArr = [
        <div>
            {`Last card${(cardsets[cardsets.length - 1] && cardsets[cardsets.length - 1].deck.getCount()) > 1 ? "s" : ""} played`}
            <hr />
        </div>
    ]
    for (let cardsetIndex = cardsets.length - 1; cardsetIndex > -1; cardsetIndex--) {
        let cardset = cardsets[cardsetIndex]
        let cards = cardset.deck.getCards()
        retArr.push(
            <div>
                {cardsetIndex === cardsets.length - 1 ? "" : <hr />}
                <div>
                    {cardset.player}
                </div>
                {cards.length !== 0 ? 
                    cards.map((card)=>{
                        return (
                            <span>
                                {CardRepresentation(card.cardName, card.suit)}
                            </span>
                        )})
                    : "Passed"
                }
            </div>
        )
    }
    retArr.push(
        <div>
            <hr />
            {`First card${cardsets[0] && cardsets[0].deck.getCount() > 1 ? "s" : ""} played`}
        </div>
    )
    return retArr
}

// Render cards that have been selected
export const renderSelectedCards = (cards:PlayingCard[], selectedCards:boolean[]) => {
    let retArr = []
    for (let i = 0; i < cards.length; i++) {
        const selCard = cards[i]
        if (selectedCards[i]) {
            retArr.push(
                <span>
                    {CardRepresentation(selCard.cardName, selCard.suit)}
                </span>
            )
        }
    }
    return retArr
}

// render information relevant to what's happening in the game (who passed, who played what)
export const renderInfo = (room:Room) => {
    if (room.cardSetFromRound.length > 0 && CardName[room.roundInfo.roundValue] !== "Joker") {
        const lastPlayedCardSet = getLastPlayedCardSet(room)
        const lastPlayedCardSetWithCards = getLastPlayedCardSetWithCards(room)
        return (
            <div>
                {lastPlayedCardSet.deck.getCards().length === 0 ? `${room.order[room.order.length - 1].playerId} passed. \n` : null}
                {lastPlayedCardSetWithCards.player} played {lastPlayedCardSetWithCards.deck.getCount()} {CardName[room.roundInfo.roundValue]}{lastPlayedCardSetWithCards.deck.getCount() > 1 ? 's': ''}.
                Total {CardName[room.roundInfo.roundValue]}{room.cardsInPlay.getCount() > 1 ? 's': ''} played is {room.cardsInPlay.getCount()}.
            </div>
        )
    }
    return null
}

// Render the grouped cards view and manage the onclick to ensure the modal is 
// opened properly
export const renderGroupedCards = (
    cards:PlayingCard[], 
    selectedCards:boolean[],
    setCards:(value: React.SetStateAction<PlayingCard[]>) => void, 
    setSelectedCards:(value: React.SetStateAction<boolean[]>) => void,
    setSelectedGroupedCard: (value: React.SetStateAction<PlayingCard | undefined>) => void,
    setShowGroupedCardsIsOpen: (value: React.SetStateAction<boolean>) => void,
    showGroupedCardsIsOpen:boolean,
    numCardsSelected:number,
    setNumCardsSelected:React.Dispatch<React.SetStateAction<number>>
) => {
    let retArr = []
    cards.sort((a, b) => {
        if (a.cardName === b.cardName) {
            return 0
        }
        if (a.cardName > b.cardName) {
            return 1
        }
        else return -1
    })
    const firstCards = Array(14).fill(-1)
    for (let i = 0; i < 14; i++) {
        const index = cards.findIndex((card)=>{return card.cardName === i})
        firstCards[i] = index
    }
    let groupedCards = groupCards(cards, selectedCards, setCards, setSelectedCards, numCardsSelected, setNumCardsSelected)
    let ownedCardnameKeySet = Object.keys(groupedCards)
    for (let ownedCardnameEnum = 0; ownedCardnameEnum <= 13; ownedCardnameEnum++) {
        if (ownedCardnameKeySet.findIndex((elem) => {return elem === ownedCardnameEnum.toString()}) === -1) {
            continue
        }
        const coverCard = cards[firstCards[ownedCardnameEnum]]
        retArr.push(
            <span>
                <span onClick={()=>{
                    setSelectedGroupedCard(coverCard);
                    setShowGroupedCardsIsOpen(!showGroupedCardsIsOpen)
                }}>
                    {firstCards[ownedCardnameEnum] !== -1 ? CardRepresentation(coverCard.cardName, coverCard.suit): ""}
                </span>
            </span>
        )
    }
    return retArr
}

// Populate the modal with the correct cards
export const renderGroupedCardModalContent = (
    cards:PlayingCard[], 
    selectedGroupedCard:PlayingCard | undefined,
    selectedCards:boolean[], 
    setCards:(value: React.SetStateAction<PlayingCard[]>) => void, 
    setSelectedCards:(value: React.SetStateAction<boolean[]>) => void,
    numCardsSelected:number,
    setNumCardsSelected:React.Dispatch<React.SetStateAction<number>>
) => {
    const groupedCards = groupCards(cards, selectedCards, setCards, setSelectedCards, numCardsSelected, setNumCardsSelected)
    if (selectedGroupedCard !== undefined) {
        return groupedCards[selectedGroupedCard.cardName]
    } 
}

// Render all cards view
export const renderCards = (
    cards:PlayingCard[],
    selectedCards:boolean[],
    setCards:(value: React.SetStateAction<PlayingCard[]>) => void, 
    setSelectedCards:(value: React.SetStateAction<boolean[]>) => void,
    numCardsSelected:number,
    setNumCardsSelected:React.Dispatch<React.SetStateAction<number>>
) => {
    let retArr = []
    cards.sort((a, b) => {
        if (a.cardName === b.cardName) {
            return 0
        }
        if (a.cardName > b.cardName) {
            return 1
        }
        else return -1
    })
    for (let i = 0; i < cards.length; i++) {
        let c = cards[i]  
        let elem = null
        if (c.cardName === CardName.Joker) {
            elem = CardRepresentation(c.cardName, Suit.Spades)
        } else {
            elem = CardRepresentation(c.cardName, c.suit)
        }
        retArr.push(
            <span
                id={`card${i.toString()}`}
                onClick={() => {
                    selectedCards[i] = !selectedCards[i]
                    setCards(cards)
                    setSelectedCards(selectedCards)
                    const changeNumSelectedCards = changeBorder(document.getElementById(`card${i.toString()}`), selectedCards[i])
                    setNumCardsSelected(numCardsSelected + changeNumSelectedCards)
                }}
                style={{
                    outline: `${selectedCards[i] ? '5px solid yellow' : ''}`,
                    margin: 1
                }}
                >
                {elem}
            </span>
        )
    }
    return retArr
}