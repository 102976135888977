import * as React from "react";
import {isOnMobile} from "./imageUtils";

function Svg6OfSpades(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={8.547}
        y={28.013}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={8.547}
          y={28.013}

          fontFamily="Arial"
        >
          {"6"}
        </tspan>
      </text>
      <path d="M28.981 49.346c-.365-5.596-11.687-16.129-12.052-18.763-.365 2.633-11.687 13.166-12.052 18.763-.255 3.956 2.557 5.596 5.114 5.596 2.769-.03 5.055-3.863 5.843-4.608.365.987-2.418 9.216-3.287 9.216h8.766c-.869 0-3.652-8.229-3.287-9.216.69.684 2.711 4.493 5.844 4.608 2.556-.002 5.366-1.64 5.111-5.596zM77.371 56.88c-.64-9.824-20.52-28.317-21.16-32.942-.641 4.623-20.52 23.115-21.16 32.943-.448 6.945 4.49 9.824 8.979 9.824 4.86-.052 8.875-6.782 10.258-8.09.64 1.733-4.246 16.181-5.772 16.181h15.392c-1.526 0-6.413-14.448-5.772-16.181 1.211 1.201 4.76 7.89 10.261 8.09 4.487-.002 9.422-2.879 8.974-9.824z" />
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-158.978}
        y={-215.124}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-158.978}
          y={-215.124}

          fontFamily="Arial"
        >
          {"6"}
        </tspan>
      </text>
      <path d="M138.173 193.764c.365 5.596 11.687 16.128 12.052 18.763.365-2.633 11.687-13.166 12.052-18.763.255-3.956-2.557-5.596-5.114-5.596-2.768.03-5.055 3.863-5.843 4.608-.365-.987 2.419-9.216 3.288-9.216h-8.767c.87 0 3.652 8.229 3.287 9.216-.69-.684-2.71-4.493-5.844-4.608-2.555.001-5.366 1.64-5.111 5.596zM35.096 186.257c.64 9.825 20.52 28.317 21.16 32.942.641-4.623 20.52-23.115 21.16-32.942.448-6.946-4.49-9.825-8.979-9.825-4.86.052-8.875 6.782-10.258 8.09-.64-1.733 4.246-16.181 5.772-16.181H48.559c1.526 0 6.413 14.448 5.772 16.182-1.211-1.202-4.76-7.89-10.261-8.091-4.487.002-9.422 2.88-8.974 9.825zM77.455 126.582c-.641-9.825-20.52-28.317-21.16-32.943-.641 4.623-20.52 23.116-21.16 32.943-.448 6.945 4.489 9.825 8.978 9.825 4.861-.052 8.876-6.783 10.259-8.091.64 1.734-4.246 16.182-5.772 16.182h15.392c-1.526 0-6.413-14.448-5.772-16.182 1.211 1.202 4.76 7.89 10.261 8.09 4.487-.002 9.422-2.879 8.974-9.824zM132.058 56.681c-.64-9.824-20.52-28.317-21.16-32.942-.641 4.623-20.52 23.115-21.16 32.942-.448 6.946 4.489 9.825 8.979 9.825 4.86-.052 8.875-6.782 10.258-8.09.64 1.733-4.246 16.181-5.772 16.181h15.392c-1.526 0-6.413-14.448-5.772-16.182 1.211 1.202 4.76 7.89 10.261 8.091 4.487-.002 9.422-2.879 8.974-9.825zM89.783 186.058c.64 9.825 20.52 28.317 21.16 32.942.641-4.623 20.52-23.115 21.16-32.942.448-6.946-4.49-9.825-8.979-9.825-4.86.052-8.875 6.782-10.258 8.09-.641-1.733 4.246-16.181 5.771-16.181h-15.391c1.526 0 6.412 14.448 5.771 16.182-1.21-1.202-4.76-7.89-10.26-8.091-4.487.002-9.422 2.88-8.974 9.825zM132.142 126.383c-.641-9.825-20.52-28.317-21.16-32.943-.641 4.623-20.52 23.115-21.16 32.943-.448 6.945 4.489 9.825 8.978 9.825 4.861-.053 8.876-6.783 10.259-8.091.64 1.734-4.246 16.182-5.772 16.182h15.392c-1.526 0-6.413-14.448-5.772-16.182 1.211 1.202 4.76 7.89 10.261 8.09 4.487-.002 9.422-2.879 8.974-9.824z" />
    </svg>
  );
}

export default Svg6OfSpades;
