import React from 'react'

import TwoClubs from './cardsTS/2OfClubs'
import ThreeClubs from './cardsTS/3OfClubs'
import FourClubs from './cardsTS/4OfClubs'
import FiveClubs from './cardsTS/5OfClubs'
import SixClubs from './cardsTS/6OfClubs'
import SevenClubs from './cardsTS/7OfClubs'
import EightClubs from './cardsTS/8OfClubs'
import NineClubs from './cardsTS/9OfClubs'
import TenClubs from './cardsTS/10OfClubs'
import JackClubs from './cardsTS/JackOfClubs2'
import QueenClubs from './cardsTS/QueenOfClubs2'
import KingClubs from './cardsTS/KingOfClubs2'
import AceClubs from './cardsTS/AceOfClubs'

import TwoSpades from './cardsTS/2OfSpades'
import ThreeSpades from './cardsTS/3OfSpades'
import FourSpades from './cardsTS/4OfSpades'
import FiveSpades from './cardsTS/5OfSpades'
import SixSpades from './cardsTS/6OfSpades'
import SevenSpades from './cardsTS/7OfSpades'
import EightSpades from './cardsTS/8OfSpades'
import NineSpades from './cardsTS/9OfSpades'
import TenSpades from './cardsTS/10OfSpades'
import JackSpades from './cardsTS/JackOfSpades2'
import QueenSpades from './cardsTS/QueenOfSpades2'
import KingSpades from './cardsTS/KingOfSpades2'
import AceSpades from './cardsTS/AceOfSpades'

import TwoDiamonds from './cardsTS/2OfDiamonds'
import ThreeDiamonds from './cardsTS/3OfDiamonds'
import FourDiamonds from './cardsTS/4OfDiamonds'
import FiveDiamonds from './cardsTS/5OfDiamonds'
import SixDiamonds from './cardsTS/6OfDiamonds'
import SevenDiamonds from './cardsTS/7OfDiamonds'
import EightDiamonds from './cardsTS/8OfDiamonds'
import NineDiamonds from './cardsTS/9OfDiamonds'
import TenDiamonds from './cardsTS/10OfDiamonds'
import JackDiamonds from './cardsTS/JackOfDiamonds2'
import QueenDiamonds from './cardsTS/QueenOfDiamonds2'
import KingDiamonds from './cardsTS/KingOfDiamonds2'
import AceDiamonds from './cardsTS/AceOfDiamonds'

import TwoHearts from './cardsTS/2OfHearts'
import ThreeHearts from './cardsTS/3OfHearts'
import FourHearts from './cardsTS/4OfHearts'
import FiveHearts from './cardsTS/5OfHearts'
import SixHearts from './cardsTS/6OfHearts'
import SevenHearts from './cardsTS/7OfHearts'
import EightHearts from './cardsTS/8OfHearts'
import NineHearts from './cardsTS/9OfHearts'
import TenHearts from './cardsTS/10OfHearts'
import JackHearts from './cardsTS/JackOfHearts2'
import QueenHearts from './cardsTS/QueenOfHearts2'
import KingHearts from './cardsTS/KingOfHearts2'
import AceHearts from './cardsTS/AceOfHearts'

import Joker from './cardsTS/BlackJoker'
import { CardName, Suit } from '../Cards'

export const MaterialCheck = () =>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16">
        <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
        <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" fill="green"/>
    </svg>

export const MaterialCross = () =>
    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="red"/>
    </svg>

export const CardRepresentation = (type:CardName, suit:Suit) => {
    if (type === CardName.Joker) {
        return <Joker />
    }
    switch (suit) {
        case Suit.Spades:
            switch (type) {
                case CardName.Two:
                    return <TwoSpades />
                case CardName.Three:
                    return <ThreeSpades />
                case CardName.Four:
                    return <FourSpades />
                case CardName.Five:
                    return <FiveSpades />
                case CardName.Six:
                    return <SixSpades />
                case CardName.Seven:
                    return <SevenSpades />
                case CardName.Eight:
                    return <EightSpades />
                case CardName.Nine:
                    return <NineSpades />
                case CardName.Ten:
                    return <TenSpades />
                case CardName.Jack:
                    return <JackSpades />
                case CardName.Queen:
                    return <QueenSpades />
                case CardName.King:
                    return <KingSpades />
                case CardName.Ace:
                    return <AceSpades />
            }
        case Suit.Clubs:
            switch (type) {
                case CardName.Two:
                    return <TwoClubs />
                case CardName.Three:
                    return <ThreeClubs />
                case CardName.Four:
                    return <FourClubs />
                case CardName.Five:
                    return <FiveClubs />
                case CardName.Six:
                    return <SixClubs />
                case CardName.Seven:
                    return <SevenClubs />
                case CardName.Eight:
                    return <EightClubs />
                case CardName.Nine:
                    return <NineClubs />
                case CardName.Ten:
                    return <TenClubs />
                case CardName.Jack:
                    return <JackClubs />
                case CardName.Queen:
                    return <QueenClubs />
                case CardName.King:
                    return <KingClubs />
                case CardName.Ace:
                    return <AceClubs />
            }
        case Suit.Diamonds:
            switch (type) {
                case CardName.Two:
                    return <TwoDiamonds />
                case CardName.Three:
                    return <ThreeDiamonds />
                case CardName.Four:
                    return <FourDiamonds />
                case CardName.Five:
                    return <FiveDiamonds />
                case CardName.Six:
                    return <SixDiamonds />
                case CardName.Seven:
                    return <SevenDiamonds />
                case CardName.Eight:
                    return <EightDiamonds />
                case CardName.Nine:
                    return <NineDiamonds />
                case CardName.Ten:
                    return <TenDiamonds />
                case CardName.Jack:
                    return <JackDiamonds />
                case CardName.Queen:
                    return <QueenDiamonds />
                case CardName.King:
                    return <KingDiamonds />
                case CardName.Ace:
                    return <AceDiamonds />
            }
        case Suit.Hearts:
            switch (type) {
                case CardName.Two:
                    return <TwoHearts />
                case CardName.Three:
                    return <ThreeHearts />
                case CardName.Four:
                    return <FourHearts />
                case CardName.Five:
                    return <FiveHearts />
                case CardName.Six:
                    return <SixHearts />
                case CardName.Seven:
                    return <SevenHearts />
                case CardName.Eight:
                    return <EightHearts />
                case CardName.Nine:
                    return <NineHearts />
                case CardName.Ten:
                    return <TenHearts />
                case CardName.Jack:
                    return <JackHearts />
                case CardName.Queen:
                    return <QueenHearts />
                case CardName.King:
                    return <KingHearts />
                case CardName.Ace:
                    return <AceHearts />
            }
    }
}
