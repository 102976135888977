import * as React from "react";
import {isOnMobile} from "./imageUtils";

function SvgAceOfClubs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <defs>
        <radialGradient
          xlinkHref="#ace_of_clubs_svg__a"
          id="ace_of_clubs_svg__e"
          cx={48.231}
          cy={18.138}
          fx={48.231}
          fy={18.138}
          r={9.5}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-1.56053 .01828 -.02684 -2.29095 123.984 58.81)"
        />
        <radialGradient
          xlinkHref="#ace_of_clubs_svg__b"
          id="ace_of_clubs_svg__f"
          cx={171.487}
          cy={511.223}
          fx={171.487}
          fy={511.223}
          r={81.903}
          gradientTransform="matrix(1.15299 -.67392 .39482 .67549 -233.633 270.4)"
          gradientUnits="userSpaceOnUse"
        />
        <radialGradient
          r={81.903}
          fy={511.223}
          fx={171.487}
          cy={511.223}
          cx={171.487}
          gradientTransform="matrix(1.15299 -.67392 .39482 .67549 -233.633 270.4)"
          gradientUnits="userSpaceOnUse"
          id="ace_of_clubs_svg__h"
          xlinkHref="#ace_of_clubs_svg__c"
        />
        <radialGradient
          r={81.903}
          fy={461.841}
          fx={181.694}
          cy={461.841}
          cx={181.694}
          gradientTransform="matrix(1.15299 -.67392 .39482 .67549 -233.633 270.4)"
          gradientUnits="userSpaceOnUse"
          id="ace_of_clubs_svg__j"
          xlinkHref="#ace_of_clubs_svg__d"
        />
        <linearGradient id="ace_of_clubs_svg__a">
          <stop offset={0} />
          <stop offset={1} stopOpacity={0.656} />
        </linearGradient>
        <linearGradient id="ace_of_clubs_svg__b">
          <stop offset={0} stopColor="#fff" stopOpacity={0.534} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="ace_of_clubs_svg__c">
          <stop offset={0} stopColor="#fff" stopOpacity={0.519} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="ace_of_clubs_svg__d">
          <stop offset={0} stopColor="#fff" stopOpacity={0.702} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <filter
          colorInterpolationFilters="sRGB"
          id="ace_of_clubs_svg__g"
          x={-0.139}
          width={1.279}
          y={-0.162}
          height={1.325}
        >
          <feGaussianBlur stdDeviation={9.511} />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          id="ace_of_clubs_svg__i"
          x={-0.139}
          width={1.279}
          y={-0.162}
          height={1.325}
        >
          <feGaussianBlur stdDeviation={9.511} />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          id="ace_of_clubs_svg__k"
          x={-0.139}
          width={1.279}
          y={-0.162}
          height={1.325}
        >
          <feGaussianBlur stdDeviation={9.511} />
        </filter>
      </defs>
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={6.711}
        y={27.548}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={6.711}
          y={27.548}

          fontFamily="Arial"
        >
          {"A"}
        </tspan>
      </text>
      <path
        d="M50.291 22.698s2.375-1.9 2.375-4.534c0-1.542-1.369-4.102-4.534-4.102s-4.534 2.561-4.534 4.102c0 2.634 2.375 4.534 2.375 4.534-2.638-2.055-7.34-.652-7.34 3.455 0 2.056 1.68 4.318 4.317 4.318 3.165 0 4.534-3.455 4.534-3.455s.402 3.938-1.943 6.046h5.182c-2.345-2.107-1.943-6.046-1.943-6.046s1.37 3.455 4.534 3.455c2.64 0 4.318-2.263 4.318-4.318 0-4.107-4.703-5.51-7.34-3.455z"
        fill="url(#ace_of_clubs_svg__e)"
        transform="matrix(5.97203 0 0 5.97203 -203.777 -22.114)"
      />
      <path
        transform="matrix(.22864 0 0 .24885 1.171 -6.44)"
        d="M117.301 604.266s-8.067-94.95 22.857-122.857c34.761-31.369 140-11.429 140-11.429s-71.54 24.838-100 48.572c-27.21 22.692-62.857 85.714-62.857 85.714z"
        fill="url(#ace_of_clubs_svg__f)"
        filter="url(#ace_of_clubs_svg__g)"
      />
      <path
        transform="matrix(.22864 0 0 .24885 33.042 -51.982)"
        d="M117.301 604.266s-8.067-94.95 22.857-122.857c34.761-31.369 140-11.429 140-11.429s-71.54 24.838-100 48.572c-27.21 22.692-62.857 85.714-62.857 85.714z"
        fill="url(#ace_of_clubs_svg__h)"
        filter="url(#ace_of_clubs_svg__i)"
      />
      <path
        transform="matrix(.23543 .1449 -.17355 .28197 159.228 -46.377)"
        d="M117.301 604.266s-8.067-94.95 22.857-122.857c34.761-31.369 140-11.429 140-11.429s-71.54 24.838-100 48.572c-27.21 22.692-62.857 85.714-62.857 85.714z"
        fill="url(#ace_of_clubs_svg__j)"
        filter="url(#ace_of_clubs_svg__k)"
      />
      <path
        d="M14.716 41.668v151.369h137.532V41.667H14.716zm68.91 20.125c18.902 0 27.083 15.29 27.083 24.499 0 15.73-14.185 27.076-14.185 27.076 15.754-12.273 43.838-3.893 43.838 20.634 0 12.272-10.027 25.787-25.788 25.787-18.901 0-27.076-20.634-27.076-20.634S85.096 162.68 99.1 175.263H68.153c14.004-12.589 11.608-36.108 11.608-36.108s-8.174 20.634-27.076 20.634c-15.754 0-25.787-13.509-25.787-25.787 0-24.527 28.084-32.907 43.838-20.634 0 0-14.185-11.346-14.185-27.076 0-9.203 8.174-24.5 27.076-24.5z"
        fill="#fffeff"
      />
      <g>
        <path d="M20.69 43.74s3.53-2.823 3.53-6.736c0-2.291-2.035-6.094-6.737-6.094s-6.736 3.804-6.736 6.094c0 3.913 3.529 6.736 3.529 6.736-3.92-3.053-10.906-.969-10.906 5.133 0 3.054 2.496 6.415 6.415 6.415 4.702 0 6.736-5.133 6.736-5.133s.597 5.85-2.887 8.982h7.699c-3.484-3.13-2.887-8.982-2.887-8.982s2.034 5.133 6.736 5.133c3.92 0 6.415-3.362 6.415-6.415 0-6.102-6.987-8.186-10.906-5.133z" />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-160.464}
        y={-214.467}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-160.464}
          y={-214.467}

          fontFamily="Arial"
        >
          {"A"}
        </tspan>
      </text>
      <g>
        <path d="M146.484 198.74s-3.529 2.823-3.529 6.736c0 2.291 2.034 6.094 6.736 6.094s6.736-3.804 6.736-6.094c0-3.913-3.528-6.736-3.528-6.736 3.919 3.053 10.906.969 10.906-5.133 0-3.054-2.496-6.415-6.415-6.415-4.702 0-6.736 5.133-6.736 5.133s-.597-5.85 2.886-8.982h-7.698c3.484 3.13 2.886 8.982 2.886 8.982s-2.033-5.133-6.736-5.133c-3.92 0-6.415 3.362-6.415 6.415 0 6.102 6.987 8.186 10.907 5.133z" />
      </g>
    </svg>
  );
}

export default SvgAceOfClubs;
