import React, { useState } from 'react';

import { RoomAndPlayerUsername } from '../Types'
import { CreatePlayer, CreateRoom, RemovePlayer } from './utils'
import { Background, Stack, HomePageButton } from './styles';
import { Link, useHistory } from 'react-router-dom';

import Markdown from 'react-markdown'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useHistoryState } from '../StateContext';

const howTo = `
# Project Bluff
The goal of this project is to create an online version of Bluff (or Indian BS) to play with others on the internet using a web application. Bluff is a card game using a standard 54 card deck (such as a normal poker deck).

## Rules
### BS
There exists another card game called BS. If you are familiar with this version, the differences are summarized below. If you've played a game called BS (or Bluff, in rare circumstances) and had the following statement be true, than this section applies to you. If a player who starts the round plays an ace, the following player must play a two, the next player a three, and so on.
The changes are as follows:
#### Every player plays the same value for the whole round
If the starting player starts with a two, everyone in that round must claim to play a two; other values are invalid. The starting player can choose whatever value suits them the best (including in the beginning of the game).
#### You can pass
If you don't feel like playing your card in that round, you may pass your turn without playing a card. This does not limit you from calling someone's bluff later, or even playing a card later. You just cede your play at this current time. This allows for people to play the entire game honestly.
#### Jokers can be anything
To mess up cardcounters, jokers can take on any value in the game and count as a valid card. For example, if someone claims to put down three 2's, and the cards played are a 2 of hearts, 2 of clubs, and a joker, this is a truthful hand, with its consequences if someone were to call it.

### General
The goal of the game is to lose all the cards in your hand. At the beginning of the game, all cards are dealt equally to all participants. The person with the Ace of Spades begins the game. There are 3 options for any player in the course of a round.
#### Play a card/cards
This is the only option for someone beginning a round (for obvious reasons). One can lie or tell the truth in this situation. Lies can include the number of cards played or the values for said cards. As an example, if one were to play two 4's, one could play 2 cards with the value of 4, a card with a value of 4 and another card, or 2 cards without the value of 4. Whoever begins the round (for any round), any card can be chosen to be played.
##### Special rule: Jokers can be anything
To mess up cardcounters, jokers can take on any value in the game and count as a valid card. For example, if someone claims to put down three 2's, and the cards played are a 2 of hearts, 2 of clubs, and a joker, this is a truthful hand, with its consequences if someone were to call it.
#### Pass/Don't play a card
If, for whatever reason, you feel like not playing a card on your turn, you can pass and just not play one. It doesn't take away from your future turns at all, so one can play a card or pick up a card later. If everyone passes in a round, the cards go out of play, and cannot be seen or recollected again. The person after the one who began gets their turn, and a new round begins.
#### Call Bluff
If you feel like the last person who played was lying, you can call their bluff and pick up their cards. It is your choice whether to show them to everyone or keep the cards secret. If the person who played the last hand was lying, they must pick up the entire deck, including their lying hand. If they were telling the truth, you must pick up the entire deck with the truthful hand.

# Changelog
5/2: 
* Fixed bugs leading to passing not working, bluff incorrectly going to the different person, added surprise for winners.

6/12: 
* Reversed directions of rendered bluff cards
* Fixed bug with starting game after game
* Added ability to join back into the game from the join menu
* Added new remove method to remove players in game
* Fixed bug with remove to check for existence of string before
  continuing
* Added refresh button to replace functionality of hard refresh on top (can fix some UI issues encountered)
* Made 6 cards appear per row on computer
`

const Homepage = () => {
    let history = useHistory()
    const {state, setState} = useHistoryState()
    const [username, setUsername] = useState<string>(state.username)

    const getCurrentUsername = ():string => {
        const elem = document.getElementById("usernameInput") as HTMLInputElement
        if (elem) {
            return elem.value
        }
        return ""
    }

    return (
        <Background>
            <ToastContainer />
            <Stack>
                {/* Should restrict characters below to alphanumeric */}
                <input type="text" placeholder="Enter a Username" id="usernameInput" value={username}
                    onChange={(t) => {
                        setUsername(t.target.value)
                    }}
                    style={{ width: "40%" }}    
                />
                <br/>
                <HomePageButton disabled={username === ""} onClick={() => {
                    if (username.length > 19) {
                        toast.error("Please enter a shorter name.")
                    }    
                    const potentialGameId = state.gameId
                    if (potentialGameId !== "") {
                        RemovePlayer(getCurrentUsername(), potentialGameId).then(()=> {
                            setState({username: state.username, gameId: ""})
                        })
                    
                    }
                    CreateRoom().then((r) => {
                        return CreatePlayer(username, r.gameId)
                    }).then((obj:RoomAndPlayerUsername) => {
                        const room = obj.room
                        const uniqueUsername = obj.username
                        // Prepares cookies, sends to lobby
                        setState({username: uniqueUsername, gameId: room.gameId})
                        history.push(`/lobby/${room.gameId}`)
                    })
                }}
                >
                    Create
                </HomePageButton>
                <br/>
                {/* Below, will eventually add ability to move username from homepage to join */}
                <Link 
                    onClick={()=>{ setState({username: getCurrentUsername(), gameId: state.gameId})}}
                    to={{
                        pathname: "/join"
                }}>
                    <HomePageButton>
                        Join
                    </HomePageButton>
                </Link>
            </Stack>
            <Markdown source={howTo}/>
        </Background>
    )
}

export default Homepage