import * as React from "react";
import {isOnMobile} from "./imageUtils";

function SvgBlackJoker(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={3.945}
        y={27.356}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={3.945}
          y={27.356}
          
          fontStyle="italic"
          fontWeight={700}
          fontFamily="LMMathSymbols9"
        >
          {"J"}
        </tspan>
      </text>
      <path d="M128.768 119.025c7.875-8.975 6.686-41.877 10.103-46.392-4.514 3.414-37.416 2.226-46.392 10.103-6.34 5.571-4.575 12.274-.726 16.124 4.213 4.123 13.427 1.794 15.735 1.858-.938 2.036-17.517 10.235-18.825 8.927l13.198 13.198c-1.308-1.309 6.89-17.888 8.927-18.825.007 2.069-2.684 10.847 1.86 15.736 3.85 3.846 10.548 5.61 16.12-.73z" />
      <text
        style={{
          lineHeight: "125%",
        }}
        x={4.273}
        y={49.051}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={4.273}
          y={49.051}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"o"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={2.63}
        y={76.992}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={2.63}
          y={76.992}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"k"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={2.63}
        y={99.345}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={2.63}
          y={99.345}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"e"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={2.301}
        y={120.383}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={2.301}
          y={120.383}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"r"}
        </tspan>
      </text>
      <path
        d="M48.112 66.907c-7.7 7.7-1.002 16.455-1.002 16.455s-8.754-6.698-16.454 1.002c-5.39 5.39-6.803 13.731-.267 20.267 8.635 8.635 32.94 1.698 48.994 11.004-9.46-15.88-2.369-40.359-11.004-48.994-6.536-6.536-14.877-5.123-20.267.266z"
        fill="#df0000"
      />
      <path d="M50.99 149.16s-9.173-1.019-14.825 4.633c-3.308 3.309-5.864 11.74.927 18.531 6.792 6.791 15.225 4.234 18.531.927 5.652-5.652 4.633-14.825 4.633-14.825 1.25 10.07 14.353 17.15 23.165 8.338 4.412-4.411 5.66-12.87 0-18.53-6.79-6.792-17.142-2.316-17.142-2.316s7.587-9.312 17.142-8.804l-11.119-11.119c.51 9.553-8.804 17.142-8.804 17.142s4.476-10.35-2.315-17.142c-5.663-5.663-14.121-4.41-18.53 0-8.814 8.813-1.733 21.915 8.337 23.166z" />
      <path
        d="M114.477 138.357c-11.133-1.67-20.155-5.658-20.155-5.658s3.988 9.023 5.657 20.156c1.665 11.132-.07 21.192-.07 21.192s10.058-1.737 21.195-.068c11.13 1.668 20.153 5.656 20.153 5.656s-3.988-9.023-5.655-20.153c-1.67-11.137.067-21.195.067-21.195s-10.06 1.735-21.192.07z"
        fill="#df0000"
      />
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-163.716}
        y={-215.002}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-163.716}
          y={-215.002}
          
          fontStyle="italic"
          fontWeight={700}
          fontFamily="LMMathSymbols9"
        >
          {"J"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-163.387}
        y={-193.306}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-163.387}
          y={-193.306}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"o"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-165.031}
        y={-165.365}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-165.031}
          y={-165.365}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"k"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-165.031}
        y={-143.012}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-165.031}
          y={-143.012}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"e"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-165.36}
        y={-121.974}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-165.36}
          y={-121.974}
          
          fontSize={38.4}
          fontStyle="italic"
          fontWeight={700}
          fontFamily="URW Chancery L"
        >
          {"r"}
        </tspan>
      </text>
    </svg>
  );
}

export default SvgBlackJoker;
