import React from "react"
import { useState } from "react"
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom"
import Game from "./Pages/Game/Game"
import Homepage from "./Pages/Homepage"
import Join from "./Pages/Join"
import Lobby from "./Pages/Lobby"
import { StateContext } from "./StateContext"
import { HistoryState } from "./Types"


const App = () => {
    const [state, setState] = useState<HistoryState>({username:"", gameId:""})
    return (
        <StateContext.Provider value={{state, setState}}>
            <Router>
                {/* Issue right now is to pass state from any individual page to the Homepage link below.  */}
                <Link to={{pathname: "/"}}>
                    <div style={{ fontSize: 40, fontFamily: "Comic Sans", position:"fixed", top:0, width: "100%", height: 50, backgroundColor: "#4180ff"  }}>
                    <span style={{ color: "#003262" }}>Project </span> 
                    <span style={{ color: "#FFC72C" }}>Bluff</span>
                    </div>
                </Link>
                <Switch>
                    <Route exact path="/">
                        <Homepage/>
                    </Route>
                    <Route path="/join" children={<Join />}/>
                    <Route path="/lobby/:gameId" children={<Lobby />}/>
                    <Route path="/start/:gameId" children={<Game />}></Route>
                </Switch>
            </Router>
        </StateContext.Provider>
    )
}

export default App