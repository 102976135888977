import * as React from "react";
import {isOnMobile} from "./imageUtils";

function Svg2OfClubs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <defs>
        <linearGradient id="2_of_clubs_svg__a">
          <stop offset={0} />
          <stop offset={1} stopOpacity={0.656} />
        </linearGradient>
        <linearGradient id="2_of_clubs_svg__b">
          <stop offset={0} stopColor="#fff" stopOpacity={0.534} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="2_of_clubs_svg__c">
          <stop offset={0} stopColor="#fff" stopOpacity={0.519} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="2_of_clubs_svg__d">
          <stop offset={0} stopColor="#fff" stopOpacity={0.702} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
      </defs>
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={8.311}
        y={27.548}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={8.311}
          y={27.548}
          fontFamily="Arial"
        >
          {"2"}
        </tspan>
      </text>
      <path d="M20.69 43.74s3.53-2.823 3.53-6.736c0-2.291-2.035-6.094-6.737-6.094s-6.736 3.804-6.736 6.094c0 3.913 3.529 6.736 3.529 6.736-3.92-3.053-10.906-.969-10.906 5.133 0 3.054 2.496 6.415 6.415 6.415 4.702 0 6.736-5.133 6.736-5.133s.597 5.85-2.887 8.982h7.699c-3.484-3.13-2.887-8.982-2.887-8.982s2.034 5.133 6.736 5.133c3.92 0 6.415-3.362 6.415-6.415 0-6.102-6.987-8.186-10.906-5.133z" />
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-158.864}
        y={-214.467}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-158.864}
          y={-214.467}
          fontFamily="Arial"
        >
          {"2"}
        </tspan>
      </text>
      <path d="M146.484 198.74s-3.529 2.823-3.529 6.736c0 2.291 2.034 6.094 6.736 6.094s6.736-3.804 6.736-6.094c0-3.913-3.528-6.736-3.528-6.736 3.919 3.053 10.906.969 10.906-5.133 0-3.054-2.496-6.415-6.415-6.415-4.702 0-6.736 5.133-6.736 5.133s-.597-5.85 2.886-8.982h-7.698c3.484 3.13 2.886 8.982 2.886 8.982s-2.033-5.133-6.736-5.133c-3.92 0-6.415 3.362-6.415 6.415 0 6.102 6.987 8.186 10.907 5.133zM89.573 55.5s5.967-4.774 5.967-11.392c0-3.874-3.44-10.307-11.392-10.307s-11.392 6.435-11.392 10.307c0 6.618 5.968 11.392 5.968 11.392-6.629-5.163-18.445-1.638-18.445 8.68 0 5.167 4.22 10.85 10.849 10.85 7.952 0 11.392-8.68 11.392-8.68s1.01 9.894-4.882 15.19h13.02c-5.892-5.294-4.882-15.19-4.882-15.19s3.44 8.68 11.392 8.68c6.631 0 10.85-5.686 10.85-10.85 0-10.318-11.817-13.843-18.445-8.68zM78.768 188.244s-5.967 4.774-5.967 11.392c0 3.875 3.44 10.307 11.392 10.307s11.392-6.435 11.392-10.307c0-6.618-5.967-11.392-5.967-11.392 6.628 5.163 18.444 1.638 18.444-8.68 0-5.167-4.22-10.85-10.849-10.85-7.952 0-11.392 8.68-11.392 8.68s-1.01-9.894 4.882-15.19h-13.02c5.892 5.294 4.882 15.19 4.882 15.19s-3.44-8.68-11.392-8.68c-6.63 0-10.85 5.686-10.85 10.85 0 10.318 11.817 13.843 18.445 8.68z" />
    </svg>
  );
}

export default Svg2OfClubs;
