import React, { Component } from "react";
import { HistoryState } from "./Types";
const { Provider, Consumer } = React.createContext({});

export type ContextType = {
  state: HistoryState,
  setState: (state:HistoryState) => void
}

// Creates a default state Context
export const StateContext = React.createContext<ContextType>(
  { 
    state: {username: "", gameId: ""}, 
    setState: state => console.warn('no state provided')
  }
)
export const useHistoryState = () => React.useContext(StateContext);

class StateContextProvider extends Component {
  state:HistoryState = {
    username: "",
    gameId: ""
  };

  setContext = () => {
    this.setState( (prevState:HistoryState) => {
      return {
        username: prevState.username,
        gameId: prevState.gameId
      };
    });
  };

 render = () => {
   return (
    <Provider value={{ username: this.state.username, gameId: this.state.gameId }} >
      {this.props.children}
    </Provider>
   )

   
 }
}

export { StateContextProvider, Consumer as StateContextConsumer };