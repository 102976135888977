import React, { useState, useEffect } from 'react'
import { Room, Player, nullRoom, nullPlayer } from '../Types'
import { useParams, Link, useHistory } from 'react-router-dom'
import { ListItem, List, Stack, Background, LobbyButton } from './styles'
import { 
    RemovePlayer,
    setReadyStatus,
    numberReady,
    getUsernames,
    startGame,
    getRoomOn,
    initRoom,
    numRealPlayers,
} from './utils'

import isObjEqual from 'fast-deep-equal'

import clipboardCopy from 'clipboard-copy';
import { ToastContainer, toast} from 'react-toastify'

import { MaterialCheck, MaterialCross } from '../Images/images'
import 'react-toastify/dist/ReactToastify.css'
import { useHistoryState } from '../StateContext'

const Lobby = () => {
    let { gameId } = useParams()
    const [ready, setReady] = useState<boolean>(false)
    const [players, setPlayers] = useState<Player[]>([])
    const [room, setRoom] = useState<Room>(nullRoom)
    const [readyToStart, setReadyToStart] = useState<boolean>(false)
    const [usernameList, setUsernameList] = useState<string[]>([])
    const [sendToGame, setSendToGame] = useState<boolean>(false)
    let history = useHistory()

    const {state, setState} = useHistoryState()

    let showingToast = false

    useEffect(() => {
        getRoomOn(gameId, (rm:firebase.database.DataSnapshot)=>{
            const r = initRoom(rm, gameId)
            if (!isObjEqual(r, nullRoom) && numRealPlayers(r.players) !== numRealPlayers(players)) {
                setPlayers(r.players)
                setRoom(r)
                setUsernameList(getUsernames(r.players))
            }
            const numReady = numberReady(r)
            if (numReady === numRealPlayers(r.players) && numReady >= 2) {
                setReadyToStart(true)
            } else {
                setReadyToStart(false)
            }
            if (r.hasStarted === true) {
                setSendToGame(true)
            }
        })
    }, [])
    let username = state.username
    window.onbeforeunload = () => {
        setReadyStatus(username, gameId, false)
    }

    if (usernameList.indexOf(username) < 0 || username === "") {
        return (
            <Background>
                <Stack>
                    <div>
                        If joining the game, give it a moment. CS is hard. :D
                    </div>
                    <div>
                        If not, it seems like you were kicked out of the game. 
                        Click here to go <Link onClick={()=>{}} to="/">back.</Link>
                    </div>
                </Stack>
            </Background>
        )
    }

    if (gameId == null || gameId === '') {
        return null
    }

    if (sendToGame) {
        history.push(`/start/${gameId}/`)
    }

    return(
        <Background>
            <Stack>
                <div>
                    Welcome {username}!
                </div>
                <br />
                <div>
                    The following is your gameID. Click on it for it to be copied.
                    <br /> 
                    <span
                        style={{ backgroundColor: "yellow" }}
                        onClick={() => {
                            if (!showingToast) {
                                showingToast = true
                                clipboardCopy(gameId)
                                .then(() => {
                                    toast.info("GameID copied!", 
                                    {
                                        position: "top-center",
                                        autoClose: 3000,
                                        draggable: true,
                                        onClose: () => {showingToast = false},
                                    })
                                })
                            }
                        }}
                    >
                        {gameId}
                    </span>
                    <ToastContainer/>
                </div>
                Make sure you don't close the window, or go to another page!
                <br />
                <div style={{ flexDirection:"column" }}>
                    Players in Lobby:
                    <List>
                        {players.map(player => {
                            // Don't show null players.
                            if (isObjEqual(player, nullPlayer)) {
                                return
                            }
                            return (
                                <ListItem>
                                    <span style={{ marginRight: 10 }}>
                                        {player.ready ? <MaterialCheck/> : <MaterialCross/>}
                                        {player.playerId}
                                    </span>
                                    <span>
                                        {username === room.host.playerId && username !== player.playerId ?
                                            <LobbyButton backgroundColor="#BD2031" width="25%" onClick={() => {
                                                RemovePlayer(player.playerId, gameId)
                                            }}>
                                                Kick
                                            </LobbyButton>
                                            : "" 
                                        }
                                    </span>
                                </ListItem>
                            )}
                        )}
                    </List>
                </div>
                <LobbyButton width="15%"
                    disabled={!(username === room.host.playerId && readyToStart)}
                    onClick={()=> {startGame(room)}}
                >
                    Begin
                </LobbyButton>
                <LobbyButton
                    onClick={() => {
                        setReady(!ready)
                        setReadyStatus(username, gameId, !ready)
                }}>
                    {ready ? "Click to Unready up!" : "Click to Ready Up!"}
                </LobbyButton>
                <LobbyButton backgroundColor="#BD2031" width="25%" onClick={()=>{
                    RemovePlayer(username, gameId).then(()=>{
                        setState({username: state.username, gameId: ""})
                    })
                    history.push(`/`)
                }}>
                    Leave Queue
                </LobbyButton>
            </Stack>
        </Background>
    )
} 

export default Lobby