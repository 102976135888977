import { Player, Room, nullCardSet, nullPlayerSet, nullPlayer, nullRoom, HistoryState, RoomAndPlayerUsername, CardSet } from '../Types'
import { Deck, getFullFrenchDeck, PlayingCard, CardName, Suit } from '../Cards'

import * as rand from 'randomstring'
import isObjEqual from 'fast-deep-equal'
import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyDeUq5__VPDDDhZso4nwLdTe7mdJj_uglA",
    authDomain: "projectbluff-bb52d.firebaseapp.com",
    databaseURL: "https://projectbluff-bb52d.firebaseio.com",
    projectId: "projectbluff-bb52d",
    storageBucket: "projectbluff-bb52d.appspot.com",
    messagingSenderId: "724032967207",
    appId: "1:724032967207:web:048511a64eb88f81657402"
    };

let fire = firebase.initializeApp(firebaseConfig)
firebase.auth().signInAnonymously()

export var database = fire.database()

export async function pushRoom(room:Room) {
    return database.ref("/games/").child(room.gameId).set(room).then(() => {
        return room
    })
}

export async function pushRoomAndUsername(obj:RoomAndPlayerUsername): Promise<RoomAndPlayerUsername> {
    const room = obj.room
    const username = obj.username
    return database.ref("/games/").child(room.gameId).set(room).then(() => {
        return { room: room, username: username }
    })
}

export async function CreatePlayer(username:string, gameId:string) {
    return getRoom(gameId).then((content:Room) => {
        const room:Room = objToRoom(content)
        if (room === nullRoom) {
            throw new Error("no room seen")
        }
        const players:Player[] = room.players
        if (firstOpenPlayerIndex(players) === -1) {
            throw new Error("no more space in the room")
        }
        username = getUniqueUsername(username, players)
        const newPlayer:Player = { playerId: username, hand: nullCardSet, ready:false }
        if (numRealPlayers(players) === 0) {
            // This control flow will be entered every time createRoom is called    
            room.host = newPlayer
            room.gameId = gameId
        }
        players[firstOpenPlayerIndex(players)] = newPlayer
        room.players = players
        return { room: room, username: username }
    }).then((obj: RoomAndPlayerUsername) => {
        return pushRoomAndUsername(obj) 
    })
}

export const getUniqueUsername = (username:string, players:Player[]) => {
    let i = 0;
    let possibleName = username
    while (getUsernames(players).indexOf(possibleName) >= 0) {
        possibleName = username + i.toString()
        i++;
    }
    return possibleName
}

// Converts from Datasnapshot to a Room
export const initRoom = (room:firebase.database.DataSnapshot, gameId:string) => {
    if (room === null) {
        // some sort of error on retrieval
        return nullRoom
    }
    if (!room.exists()) {
        // room doesn't exist
        return nullRoom
    }
    const temp:any = room.toJSON()
    return objToRoom(temp)
}

const objToRoom = (temp:any):Room => {
    if (temp === null) {
        // turning data into room yielded null
        return nullRoom
    }  
    if (!temp.hasOwnProperty("players")) {
        temp.players = nullPlayerSet
    } else {
        let arr = []
        for(var playerIndex in temp.players) {
            let cardSet:CardSet = {player: temp.players[playerIndex].playerId, deck: new Deck()}
            temp.players[playerIndex].hand = cardSet
            arr.push(temp.players[playerIndex])
        }
        temp.players = arr
    }
    if (!temp.hasOwnProperty("order")) {
        temp.order = []
    } else {
        let arr = []
        for(var playerIndex in temp.order) {
            arr.push(temp.order[playerIndex])
        }
        temp.order = arr
    }

    if (!temp.hasOwnProperty("cardsInPlay")) {
        temp.cardsInPlay = new Deck()
    } else {
        temp.cardsInPlay = new Deck(Object.values(temp.cardsInPlay.cards))
    }

    if (!temp.hasOwnProperty("cardSetFromRound")) {
        temp.cardSetFromRound = []
    } else {
        temp.cardSetFromRound = Object.values(temp.cardSetFromRound)
    }

    if (!temp.hasOwnProperty("cardSetFromLastRound")) {
        temp.cardSetFromLastRound = []
    } else {
        temp.cardSetFromLastRound = Object.values(temp.cardSetFromLastRound)
    }
    let r = temp as Room
    if (r.hasStarted) {
        for (let cardSet of r.cardSetFromRound) {
            if (cardSet.deck === undefined) {
                cardSet.deck = new Deck()
            } else {
                cardSet.deck = new Deck(Object.values(cardSet.deck.cards))
            }
        }
        
        for (let cardSet of r.cardSetFromLastRound) {
            if (cardSet.deck === undefined) {
                cardSet.deck = new Deck()
            } else {
                cardSet.deck = new Deck(Object.values(cardSet.deck.cards))
            }
        }

        if (r.bluffCaller.hand.deck === undefined) {
            r.bluffCaller.hand.deck = new Deck()
        } else {
            r.bluffCaller.hand.deck = new Deck(Object.values(r.bluffCaller.hand.deck.cards))
        }

        if (r.host.hand.deck === undefined) {
            r.host.hand.deck = new Deck()
        } else {
            r.host.hand.deck = new Deck(Object.values(r.host.hand.deck.cards))
        }

        if (r.roundInfo.startPlayer.hand.deck === undefined) {
            r.roundInfo.startPlayer.hand.deck = new Deck()
        } else {
            r.roundInfo.startPlayer.hand.deck = new Deck(Object.values(r.roundInfo.startPlayer.hand.deck.cards))
        }

        let dict = new Map()
        // will run a max of 54 times (n in the number of cards)
        for (let i = 0; i < r.order.length; i++) {
            if (r.order[i].hand.deck === undefined) {
                r.order[i].hand.deck = new Deck()
            } else {
                r.order[i].hand.deck = new Deck(r.order[i].hand.deck.cards)
            }
            let cardArr = []
            const tempCards = Object.values(r.order[i].hand.deck.cards)
            for (let j = 0; j < tempCards.length; j++) {
                cardArr.push(new PlayingCard(tempCards[j].cardName, tempCards[j].suit))
            }
            r.order[i].hand.deck = new Deck(cardArr)
            dict.set(r.order[i].playerId, cardArr)
        }

        for (let i = 0; i < r.order.length; i++) {
            r.order[i].hand.deck = new Deck(dict.get(r.order[i].playerId))
        }
    } else {
        r.order.forEach(player => {
            player.hand.deck = new Deck()
        });
    }
    return r
}
// database is correct, error must be in the function that receives info. New note:
// database got OVERWRITTEN, so there must be an extra call to a pushRoom that changes the informationd
export async function getRoom(gameId:string):Promise<Room> {
    if (gameId === "") {
        return nullRoom
    }
    return database.ref("/games/").child(gameId).once("value").then(
        (room) => {
            return initRoom(room, gameId)
        }
    )
}

export async function getRoomOn(gameId:string, callback:any) {
    return database.ref("/games/").child(gameId).on("value", callback)
}

export async function gameRoomExists(gameId:string):Promise<boolean> {
    return getRoom(gameId).then(room => {return room !== nullRoom})
}

// Creates the gameid associated with a given room, posts to github gists to ensure it exists for
// later. The gist will capture the usernames in the lobby
export async function CreateRoom():Promise<Room> {
    var r : Room
    r = nullRoom
    let tempGameId = rand.generate(20)
    while ((await getRoom(tempGameId)) !== nullRoom) {
        tempGameId = rand.generate(20)
    }
    r.gameId = tempGameId
    return database.ref("/games/").child(r.gameId).set(r).then(() => {
        return r
    })
}

export const startGame = (room:Room) => {
    // Initialize the deck and shuffle
    room.hasStarted = true
    let augDeck = getFullFrenchDeck()
    augDeck.push(new PlayingCard(CardName.Joker, Suit.Spades), new PlayingCard(CardName.Joker, Suit.Spades))
    const deck = new Deck(augDeck)
    deck.shuffle()

    let order:Player[] = []
    for (let playerIndex = 0; playerIndex < room.players.length; playerIndex++) {
        if (!isObjEqual(room.players[playerIndex], nullPlayer)) {
            order.push(room.players[playerIndex])
        }
    }
    shuffleArray(order)

    // Give all the cards to all of the players
    const minNum = Math.floor(deck.getCount()/order.length)
    let rem = deck.getCount() % order.length
    for (let i = 0 ; i < order.length; i++) {
        let player = order[i]
        player.ready = false
        player.hand.deck = new Deck()
        if (rem > 0) {
            deck.deal(player.hand.deck, minNum + 1)
            rem--
        } else {
            deck.deal(player.hand.deck, minNum)
        }
        order[i] = player
    }


    // Figure out the order of the players based on the Ace of Spades
    for (let i = 0; i < order.length; i++) {
        if (order[i].hand.deck.hasCard(new PlayingCard(CardName.Ace, Suit.Spades))) {
            for (let j = 0; j < order.length; j++) {
                room.order.push(order[(j + i) % order.length])
            }
        }
    }
    room.players = []
    return pushRoom(room)
}

export async function callBluff(gameId:string, callingPlayer:Player):Promise<boolean> {
    let success = false
    return database.ref("/games/").child(gameId).transaction(function(room:Room) {
        if (room.bluffCaller.playerId === "") {
            room.bluffCaller = callingPlayer
            success = true
            return room
        }
        return;
    }).then(() => {
        return success
    })
}

// Intended to be called to move cards during a bluff call
export function moveCardsWithinRoom(room:Room) {
    if (room.bluffCaller.playerId === "") {
        return nullRoom;
    }
    // assumed that lastPlayedCardSet player is truthful
    let user = room.bluffCaller.playerId
    const lastPlayedCardSet = getLastPlayedCardSetWithCards(room)
    let newStarter = lastPlayedCardSet.player
    // checks if the lastPlayedCardSet has a lie within it
    for (let i = 0; i < lastPlayedCardSet.deck.getCount(); i++) {
        if (lastPlayedCardSet.deck.cardAtIndex(i).cardName !== room.roundInfo.roundValue &&
            lastPlayedCardSet.deck.cardAtIndex(i).cardName !== CardName.Joker) {
            user = lastPlayedCardSet.player
            newStarter = room.bluffCaller.playerId
            break
        }
    }
    // transfers the cards to the right player
    // Issue may be here in room.order[i]
    for (let i = 0; i < room.order.length; i++) {
        if (room.order[i].playerId === user) {
            room.order[i].hand.deck.addCards(room.cardsInPlay.getCards())
            break
        }
    }
    room.cardsInPlay.takeCards(0)
    return makePlayerNext(room, newStarter)
}

// Moves cards appropriately from the played cards to someone's deck,
// Resets all appropriate parameters, except for the bluffCaller.
export async function moveCards(gameId:string) {
    return database.ref("/games/").child(gameId).transaction(function(r) {
        const room = objToRoom(r)
        return moveCardsWithinRoom(room)
    })
}

// Has no dependency with processNextTurn (if called after this function)
// player with username === playerId becomes last
export const makePlayerHeadOfOrder = (room:Room, playerId:string) => {
    let arr:Player[] = []
    while (room.order.length > 0) {
        if (room.order[0].playerId === playerId) {
            room.order = room.order.concat(arr)
            console.log(room.order)
            break
        } else {
            let elem = room.order.shift()
            if (elem === undefined) {
                throw new Error("removed from empty order")
            } else {
                arr.push(elem)
            }
        }
    }
}

// INTENDED TO BE CALLED BEFORE processNextTurn
export const makePlayerNext = (room:Room, playerId:string) => {
    makePlayerHeadOfOrder(room, playerId)
    let elem = room.order.pop()
    if (elem === undefined) {
        throw new Error("removed from empty order")
    } else {
        room.order.unshift(elem)
    } 
    return room
}

// only roundValue is changed when beginning
export async function processNextTurn(room:Room) {
    let elem = room.order.shift()
    if (elem === undefined) {
        throw new Error("removed from empty order")
    } else {
        room.order.push(elem)
    }
    return pushRoom(room)
}

export function finishRound(room:Room) {
    room.bluffCaller = nullPlayer
    room.cardSetFromRound = []
    room.cardsInPlay.takeCards(0)
    room.roundInfo.passNum = -1
    room.roundInfo.roundValue = CardName.Joker
    return room
}

export async function RemovePlayer(username:string|null, gameId:string) {
    if (username === null) {
        return
    }
    getRoom(gameId).then(room => {
        if (
            isObjEqual(room, nullRoom) || 
            ( 
                room.players.find((player) => {return player.playerId === username}) === undefined 
                && 
                room.order.find((player) => {return player.playerId === username}) === undefined
            )
        ) {
            return
        } else {
            if (room.hasStarted) {
                removePlayerInGame(username, gameId)
            } else {
                removePlayerBeforeGame(username, gameId)
            }
        }
    })
}

// remove players from a game that has already started
async function removePlayerInGame(username:string, gameId:string) {
    return getRoom(gameId).then((room:Room) => {
        if (isObjEqual(room, nullRoom)) {
            return nullRoom
        }
        if (room.order.length === 1) {
            RemoveRoom(gameId)
            return nullRoom
        }
        let cards:Deck = new Deck()
        for (let i = 0; i < room.order.length; i++) {
            if (room.order[i].playerId === username) {
                const removedPlayer = room.order[i]
                if (removedPlayer.hand.deck && removedPlayer.hand.deck.getCount() !== 0) {
                    cards.addCards(removedPlayer.hand.deck.takeCards(0))
                    cards.shuffle()
                }
                let j = -1;
                while (!cards.isEmpty()) {
                    j = (j + 1) % room.order.length
                    if (room.order[j] === nullPlayer) {
                        continue
                    }
                    const card:PlayingCard = cards.takeCard()
                    room.order[j].hand.deck.addCard(card)
                }
                room.order.splice(i, 1)
                break
            }
        }
        return room
    }).then((room:Room) => {
        if (isObjEqual(room, nullRoom)) {
            return
        }
        return pushRoom(room)
    })
}

// Run to remove players before cards are assigned (i.e. before the game starts)
async function removePlayerBeforeGame(username:string, gameId:string) {
    return getRoom(gameId).then((room:Room) => {
        if (isObjEqual(room, nullRoom)) {
            return nullRoom
        }
        console.log(numRealPlayers(room.players))
        if (numRealPlayers(room.players) === 1) {
            room.players.splice(0, 1)
            RemoveRoom(gameId)
            return nullRoom
        }
        // never need to worry about nullPlayers, as they will never be removed
        for (let i = 0; i < room.players.length; i++) {
            if (room.players[i].playerId === username) {
                if (isObjEqual(room.host, room.players[i])) {
                    // find next player to make them the host
                    room.players[i] = nullPlayer
                    room.host = room.players[firstOpenPlayerIndex(room.players)]
                }
                room.players[i] = nullPlayer
                break
            }
        }
        return room
    }).then((room:Room) => {
        if (isObjEqual(room, nullRoom)) {
            return
        }
        return pushRoom(room)
    })
}

export async function RemoveRoom(gameId:string) {
    return database.ref("/games/").child(gameId).remove()
}

export const getStateAfterSetUsername = (username:string, state:HistoryState) => {
    state["username"] = username
    return state
}

export const getStateAfterSetGameId = (gameId:string, state:HistoryState) => {
    state["gameId"] = gameId
    return state
}

export const getUsername = (state:HistoryState):string => {
    if (!state) {
        return ""
    }
    return (state["username"] || "") as string
}

export const getGameId = (state:{[index: string]:any}) => {
    if (!state) {
        return ""
    }
    return (state["gameId"] || "") as string
}

export const setReadyStatus = (username:string, gameId:string, readyStatus:boolean) => {
    getRoom(gameId).then((room:Room) => {
        if (isObjEqual(room, nullRoom)) {
            return
        }
        for (let i = 0; i < room.players.length; i++) {
            if (room.players[i].playerId === username) {
                room.players[i].ready = readyStatus
                break
            }
        }
        return pushRoom(room)
    })
}

export const numberReady = (room:Room) => {
    let count = 0
    for (let i = 0; i < room.players.length; i++) {
        if (room.players[i].ready) {
            count++;
        }
    }
    return count
}

export const getLastPlayedCardSet = (room: Room) => {
    if (room.cardSetFromRound.length === 0) {
        return nullCardSet
    }
    return room.cardSetFromRound[room.cardSetFromRound.length - 1]
}

export const getLastPlayedCardSetWithCards = (room:Room) => {
    for (let index = room.cardSetFromRound.length - 1; index > -1; index--) {
        if (room.cardSetFromRound[index].deck.getCount()) {
            return room.cardSetFromRound[index]
        }
    }
    return nullCardSet
}

// Only use this in the Lobby, as this will get all the usernames, including those
// of null players
export const getUsernames = (players:Player[]) => {
    let arr:string[] = []
    for (let i = 0; i < players.length; i++) {
        arr.push(players[i].playerId)
    }

    return arr
}

export const numRealPlayers = (players:Player[]):number => {
    let num = 0
    players.forEach(player => {
        if (!isObjEqual(player, nullPlayer)) {
            num += 1
        }
    });
    return num 
}

// Yields the first open index in the players array that can be 
// filled by a new player
const firstOpenPlayerIndex = (players:Player[]):number => {
    for (let i = 0; i < players.length; i++) {
        if (isObjEqual(players[i], nullPlayer)) {
            return i
        }
    }
    return -1
}

// Shuffles array in-place
function shuffleArray(array:any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}
