import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import Select, { ValueType } from 'react-select'
import isObjEqual from 'fast-deep-equal'
import { ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useSound from 'use-sound';

import { CardName, PlayingCard, Deck } from '../../Cards'
import { GameButton, Background, GameInfo, CardInfo, SelectContainer } from '../styles'
import { useHistoryState } from '../../StateContext'
import Modal from '../Modal'
import { CardSet, nullRoom, nullPlayer, Room } from '../../Types'
import {
    processNextTurn,
    RemoveRoom,
    finishRound,
    makePlayerHeadOfOrder,
    RemovePlayer,
    getLastPlayedCardSet,
    getLastPlayedCardSetWithCards,
    moveCards
} from '../utils'
import { 
    allCardOptions,
    bluffOnClick, 
    cardOptions, 
    changeBorder, 
    getCardNameFromSelect, 
    getInfoFromDatabase, 
    OptionType, renderBluffCards, renderCards, 
    renderGroupedCardModalContent, 
    renderGroupedCards, 
    renderInfo, 
    renderSelectedCards, 
    showOpponentHands 
} from './gameUtils'
import Confetti from 'react-confetti'

const Game = () => {
    let { gameId } = useParams()
    const [room, setRoom] = useState<Room>(nullRoom)
    const [isTurn, setIsTurn] = useState<boolean>(false)
    const [currentPlayerId, setCurrentPlayerId] = useState<string>("")
    const [bluffCalled, setBluffCalled] = useState<boolean>(false)
    const [cards, setCards] = useState<PlayingCard[]>([])
    const [winner, setWinner] = useState<string>("")
    const [roundVal, setRoundVal] = useState<ValueType<OptionType>>({value: 'Joker', label:'Joker'})

    // States for the grouped cards
    const [showGroupedCardsIsOpen, setShowGroupedCardsIsOpen] = useState<boolean>(false)
    const [selectedGroupedCard, setSelectedGroupedCard] = useState<PlayingCard>();

    const [showSelectedCards, setShowSelectedCards] = useState<boolean>(true)
    const [selectedCardsIsOpen, setSelectedCardsIsOpen] = useState<boolean>(false)

    const [bluffModalIsOpen, setBluffModalIsOpen] = useState<boolean>(false)
    const [bluffModalText, setBluffModalText] = useState<React.ReactNode>("")
    const [controlBluffModalIsOpen, setControlBluffModalIsOpen] = useState<boolean>(false)

    const {state, setState} = useHistoryState()
    let history = useHistory()
    
    // sounds to notify what happened
    const [playIsTurnNotification] = useSound(`${process.env.PUBLIC_URL}/sounds/notificationSound.mp3`, {volume: 0.5});
    const [playSomeonePlayedSound] = useSound(`${process.env.PUBLIC_URL}/sounds/otherPlayerPlayed.mp3`, {volume: 0.5});

    const [selectedCards, setSelectedCards] = useState<boolean[]>([])
    const [numCardsSelected, setNumCardsSelected] = useState<number>(0)
    // defaults true
    const [showBluffToast, setShowBluffToast] = useState<boolean>(true)
    // defaults false
    const [showTurnToast, setShowTurnToast] = useState<boolean>(false)

    const username = state.username
    const player = room.order.find(p => p.playerId === username)

    // Below two functions will work for web: will remove player if they leave.
    window.onbeforeunload = function() {
        return "This will cause you to leave the game. Are you sure you want to leave?";
    };
    window.onunload = function () {
        RemovePlayer(username, gameId)
    }
    
    // Defines the UseEffect updates for room/game related changes
    useEffect(
        () => {
            getInfoFromDatabase(
                gameId,
                room,
                player || nullPlayer,
                username,
                currentPlayerId,
                setCurrentPlayerId,
                showTurnToast,
                setShowTurnToast,
                setRoom,
                showBluffToast,
                setShowBluffToast,
                setWinner,
                setRoundVal,
                setControlBluffModalIsOpen,
                setIsTurn,
                setCards,
                setSelectedCards,
                playSomeonePlayedSound,
                playIsTurnNotification
            )
        },
            [room]
    )

    if (room.order.length === 1) {
        setWinner(room.order[0].playerId)
    }

    // add functionality for continuing later
    if (winner !== '' && player !== undefined) {
        setTimeout(()=>{RemoveRoom(room.gameId).then(()=>{setState({username: state.username, gameId: ""}); history.push("/")})}, 30000)
            if (winner === player.playerId) {
                return (
                    <Background>
                        <Confetti />
                        <Modal isOpen={room.showCardSetFromRound && !controlBluffModalIsOpen} buttonOnClick={()=>{setControlBluffModalIsOpen(true)}}>
                            {renderBluffCards(room.cardSetFromLastRound)}
                        </Modal>
                        <div style={{textAlign: "center", fontSize: 30}}>
                            {`You won! Click below to head back to the title screen:\n`}
                            <Link onClick={()=>{setState({username: state.username, gameId: ""})}} to={{pathname: "/"}}>
                                Back to Title
                            </Link>
                        </div>
                    </Background>
                )
            }
            return (
                <Background>
                    <Modal isOpen={room.showCardSetFromRound && !controlBluffModalIsOpen} buttonOnClick={()=>{setControlBluffModalIsOpen(true)}}>
                        {renderBluffCards(room.cardSetFromLastRound)}
                    </Modal>
                    <div style={{textAlign: "center", fontSize: 30}}>
                        {`${winner} has won the game! Click below to head back to the title screen:\n`}
                        <Link onClick={()=>{setState({username: state.username, gameId: ""})}} to={{pathname: "/"}}>
                            Back to Title
                        </Link>
                    </div>
                </Background>
            )
    }
    
    if (bluffCalled) {
        setBluffCalled(false)
    }

    if (room.roundInfo.passNum === room.order.length - 1 && room.order.length > 0) {
        makePlayerHeadOfOrder(room, room.roundInfo.startPlayer.playerId)
        const finishedRoom = finishRound(room)
        // cardsInPlay go out of the game
        processNextTurn(finishedRoom)
    }

    if (isObjEqual(room, nullRoom)) {
        return (
            <Background>
                Loading the game ....
            </Background>
        )
    }

    return (
        <Background>
            <ToastContainer />
            <Modal isOpen={room.showCardSetFromRound && !controlBluffModalIsOpen} buttonOnClick={()=>{setControlBluffModalIsOpen(true)}}>
                {renderBluffCards(room.cardSetFromLastRound)}
            </Modal>
            <Modal isOpen={bluffModalIsOpen} buttonOnClick={()=>{}} showX={false}>
                {bluffModalText}
            </Modal>
            <Modal isOpen={selectedCardsIsOpen} buttonOnClick={()=>{setSelectedCardsIsOpen(!selectedCardsIsOpen)}}>
                {renderSelectedCards(cards, selectedCards)}
            </Modal>
            <Modal isOpen={showGroupedCardsIsOpen} buttonOnClick={()=>{setShowGroupedCardsIsOpen(!showGroupedCardsIsOpen)}}>
                {renderGroupedCardModalContent(
                    cards,
                    selectedGroupedCard,
                    selectedCards,
                    setCards,
                    setSelectedCards,
                    numCardsSelected,
                    setNumCardsSelected
                )}
            </Modal>
            <SelectContainer>
                <Select
                    isSearchable={false}
                    options={cardOptions}
                    isDisabled={!(isTurn && room.roundInfo.roundValue === CardName.Joker)}
                    onChange={(selectedOption) => {
                        setRoundVal(selectedOption)
                    }}
                    value={allCardOptions.find((cardOption) => {return CardName[getCardNameFromSelect(roundVal)] === cardOption.value})}
                />
            </SelectContainer>
            <br />
            <CardInfo> 
                {showSelectedCards ? 
                    renderCards(
                        cards,
                        selectedCards,
                        setCards,
                        setSelectedCards,
                        numCardsSelected,
                        setNumCardsSelected
                    ) : 
                    renderGroupedCards(
                        cards,
                        selectedCards,
                        setCards,
                        setSelectedCards,
                        setSelectedGroupedCard,
                        setShowGroupedCardsIsOpen,
                        showGroupedCardsIsOpen,
                        numCardsSelected,
                        setNumCardsSelected
                    )
                }
            </CardInfo>
            <GameInfo>
                <div>
                    <GameButton
                        disabled={!isTurn}
                        onClick={() => {
                            // only first player of a round will enter this if statement
                            if (room.roundInfo.roundValue === CardName.Joker) {
                                room.showCardSetFromRound = false
                                if (player !== undefined) {
                                    room.roundInfo.startPlayer = player
                                }
                                if (getCardNameFromSelect(roundVal) !== CardName.Joker) {
                                    room.roundInfo.roundValue = getCardNameFromSelect(roundVal)
                                } else {
                                    if (!toast.isActive("dropdownInfo")) {
                                        toast.error("You must choose a round value using the dropdown menu.", {
                                            toastId: "dropdownInfo", 
                                        })
                                    }
                                    return
                                }
                            }
                            // someone has called bluff; should not allow the playing of cards until bluff finishes
                            if (room.bluffCaller.playerId !== "") {
                                if (!toast.isActive("bluffInfo")) {
                                    toast.info("Someone has called bluff! You can't play until they choose to show the deck or keep it hidden.", {
                                        toastId: "bluffInfo",
                                        autoClose: 3000
                                    })
                                }
                            }
                            // find all selected cards
                            const cardArr:PlayingCard[] = []
                            const newCards = []
                            for (let i = 0; i < cards.length; i++) {
                                const selCard = cards[i]
                                if (selectedCards[i]) {
                                    cardArr.push(selCard)
                                    changeBorder(document.getElementById(`card${i.toString()}`), false)
                                } else {
                                    newCards.push(selCard)
                                }
                            }
                            if (cardArr.length === 0) {
                                if (!toast.isActive("passInfo")) {
                                    toast.error("You haven't selected any cards to play? Did you want to pass?", {
                                        toastId: "passInfo", 
                                    })
                                }
                                return
                            }
                            // create new selectedCards array
                            let newSelCards = []
                            for (let i = 0; i < newCards.length; i++) {
                                newSelCards.push(false)
                            }
                            // selectedCards = newSelCards
                            setSelectedCards(newSelCards)
                            // adjust the last played card set
                            let justPlayedCardSet:CardSet = {
                                player: username,
                                deck: new Deck(cardArr)
                            }
                            room.cardSetFromRound.push(justPlayedCardSet)
                            
                            // add cards to the pile
                            if (room.cardsInPlay.getCount() === 0) {
                                room.bluffStatus = ""
                                // setBluffCalled(false)
                            }
                            room.cardsInPlay.addCards(cardArr)
                            // update personal deck
                            for (let i = 0; i < room.order.length; i++) {
                                if (room.order[i].playerId === username) {
                                    room.order[i].hand.deck = new Deck(newCards)
                                    break;
                                }
                            }
                            setCards(newCards)
                            setNumCardsSelected(0)

                            room.roundInfo.passNum = -1
                            processNextTurn(room).then((r) => {
                                setRoom(r)
                            })
                        }}
                    >
                        Play
                    </GameButton>
                    <GameButton
                        disabled={room.cardSetFromRound.length === 0 || getLastPlayedCardSetWithCards(room).player === username || room.cardsInPlay.getCount() == 0 }
                        onClick={
                            ()=>{
                                bluffOnClick(room, player, gameId, username, setRoom, setBluffCalled)
                                    .then((roomAndMessage:any) => {
                                        if (roomAndMessage === undefined) {
                                            return
                                        }
                                        let r:Room = roomAndMessage["room"]
                                        let message:string = roomAndMessage["message"]
                                        if (isObjEqual(r, nullRoom)) {
                                            return
                                        }
                                        setBluffModalText(
                                            <div>
                                                <div>
                                                    {message}
                                                </div>
                                                <GameButton width="45%" onClick={()=>{
                                                    r.cardSetFromLastRound = r.cardSetFromRound
                                                    r.showCardSetFromRound = true
                                                    const finishedRoom = finishRound(r)
                                                    moveCards(gameId)
                                                    processNextTurn(finishedRoom)
                                                    setBluffModalIsOpen(false)
                                                }}>
                                                    Show the deck
                                                </GameButton>
                                                <GameButton width="45%" onClick={()=>{
                                                    // do nothing
                                                    const finishedRoom = finishRound(r)
                                                    moveCards(gameId)
                                                    processNextTurn(finishedRoom)
                                                    setBluffModalIsOpen(false)
                                                }}>
                                                    Don't show the deck
                                                </GameButton>
                                            </div>
                                        )
                                        setBluffModalIsOpen(true)
                                    })
                            }
                        }
                    >
                        Call Bluff
                    </GameButton>
                    <GameButton
                        disabled={!isTurn || room.cardSetFromRound.length === 0}
                        onClick={() => {
                            room.roundInfo.passNum = room.roundInfo.passNum + 1
                            room.cardSetFromRound.push({player: username, deck: new Deck()})
                            processNextTurn(room).then((r) => {
                                setRoom(r)
                            })
                        }}
                    >
                        Pass
                    </GameButton>
                    <GameButton
                        width="50%"
                        onClick={()=>{
                            // if (!toast.isActive("opponentInfo")) {
                                toast.info(<div>{showOpponentHands(room, username)}</div>, {
                                    toastId: "opponentInfo", 
                                })
                            // }
                        }}
                    >
                        Show Rival Order/Hands
                    </GameButton>
                    <GameButton
                        width="50%"
                        onClick={()=>{
                            setShowSelectedCards(!showSelectedCards)
                        }}
                    >
                        {`${showSelectedCards ? "Show Grouped Cards" : "Show All Cards" }`}
                    </GameButton>
                    <GameButton
                        width="50%"
                        onClick={()=>{
                            // pop up modal to show all selected cards
                            setSelectedCardsIsOpen(!selectedCardsIsOpen)
                        }}
                        disabled={numCardsSelected === 0}
                    >
                        Show Selected Cards
                    </GameButton>
                    <GameButton width="10%" onClick={()=>{
                        // Need to pull from database again
                        getInfoFromDatabase(
                            gameId,
                            room,
                            player || nullPlayer,
                            username,
                            currentPlayerId,
                            setCurrentPlayerId,
                            showTurnToast,
                            setShowTurnToast,
                            setRoom,
                            showBluffToast,
                            setShowBluffToast,
                            setWinner,
                            setRoundVal,
                            setControlBluffModalIsOpen,
                            setIsTurn,
                            setCards,
                            setSelectedCards,
                            playSomeonePlayedSound,
                            playIsTurnNotification
                        )
                        if (!toast.isActive("refresh")) {
                            toast.info("Refreshing the page!", {
                                toastId: "refresh", 
                                autoClose: 5000,
                                onClose: ()=>{
                                    setShowBluffToast(true)
                                },
                            })
                        }
                    }}>
                        ↻
                    </GameButton>
                </div>
                <hr style={{ width: "65%" }} />
                <div style={{fontSize: 25}}>
                    {`${room.order[0].playerId}'s Turn! `}
                    {renderInfo(room)}
                    {player !== undefined ? ` You have ${player.hand.deck.getCount()} card${player.hand.deck.getCount() > 1 ? 's': ''}.` : null}
                </div>
            </GameInfo>
        </Background>
    )
}

export default Game