import styled from '@emotion/styled'

export const Background = styled.div({
    marginTop: 50,
    textAlign: "center"
})

export const GameInfo = styled.div({
    position: "fixed",
    bottom: 3,
    backgroundColor: "#41DFFF",
    width:"90%",
    textAlign: "center",
    margin: "auto",
    borderRadius: 10,
    paddingBottom: 5,
    left: "5%"
})

export const CardInfo = styled.div({
    float:"left",
    width:"100%", 
    marginTop: 20, 
    marginBottom: 120
})

export const SelectContainer = styled.div({ 
    position:"fixed", 
    top: 50, 
    width: "100%" 
})

export const Stack = styled.div({
    textAlign: "center",
    marginBottom: "5px"
})

export const ListItem = styled.div({
    borderColor: "#003262",
    backgroundColor: "#0047d6",
    color: "#ffffff",
    padding: "10px",
    marginBottom: "5px"
})

export const List = styled.div({
    padding: "5px",
    width: "61.5%",
    display: "flex",
    flexDirection: "column",
    margin: "auto"
})

type ButtonProps = {
    backgroundColor?: string,
    disabled?: boolean,
    width?: string,
    height?: number
}

const buttonBackgroundColor = (props:ButtonProps):string => {
    if (props.disabled) {
        return "lightblue"
    } else if (props.backgroundColor) {
        return props.backgroundColor
    }
    return '#FFC72C'
}

function buttonWidth(props:ButtonProps, defaultValue:string) {
    return props.width ? props.width : defaultValue
}

function buttonHeight(props:ButtonProps, defaultValue:number) {
    return props.height ? props.height : defaultValue
}

// using position:fixed
export const GameButton = styled.button(
    {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10
    },
    (props:ButtonProps) => ({
        backgroundColor: buttonBackgroundColor(props),
        color: props.disabled ? 'grey': 'black',
        borderColor: props.disabled ? 'white' : "black",
        width: buttonWidth(props, "20%"),
        height: buttonHeight(props, 30)
    })
)

export const LobbyButton = styled.button(
    {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10
    },
    (props:ButtonProps) => ({
        backgroundColor: buttonBackgroundColor(props),
        color: props.disabled ? 'grey': 'black',
        borderColor: props.disabled ? 'white' : "black",
        width: buttonWidth(props, "35%"),
        height: buttonHeight(props, 30)
    })
)

export const HomePageButton = styled.button(
    {
        marginTop: 5
    },
    (props:ButtonProps) => ({
        backgroundColor: buttonBackgroundColor(props),
        color: props.disabled ? 'grey': 'black',
        borderColor: props.disabled ? 'white' : "black",
        width: buttonWidth(props, "25%"),
        height: buttonHeight(props, 30)
    })
)
