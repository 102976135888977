import { PlayingCard } from './Card';

export class Deck {
    cards:PlayingCard[]
    constructor(cards:PlayingCard[] = []) {
        this.cards = cards
    }

    
    shuffle():void {
        for (let i = this.cards.length - 1; i > 0; i--) {
            const newIndex = Math.floor(Math.random()*this.cards.length)
            const temp = this.cards[i]
            this.cards[i] = this.cards[newIndex]
            this.cards[newIndex] = temp
        }
    }

    deal(deck:Deck, num:number):void {
        if (this.cards.length < num) {
            throw new Error("Trying to deal too many cards from this deck")
        }
        deck.addCards(this.takeCards(num))
    }

    hasCard(card:PlayingCard):boolean {
        const s = card.suit
        const c = card.cardName
        for (let i = 0; i < this.cards.length; i++) {
            if (this.cards[i].cardName === c && this.cards[i].suit === s) {
                return true
            }
        }
        return false
    }

    // if 0, returns all the cards in the deck, and empties the deck.
    takeCards(num:number):PlayingCard[] {
        if (this.cards.length < num) {
            throw new Error("Trying to take too many cards from this deck")
        }
        let arr:PlayingCard[] = []
        if (num === 0) {
            arr = this.cards
            this.cards = []
            return arr
        }
        for (let i = 0; i < num; i++) {
            arr.push((this.cards.pop() as PlayingCard))
        }
        return arr
    }

    takeCard():PlayingCard {
        const elem = this.cards.pop()
        if (elem === undefined) {
            throw new Error("trying to take card from empty deck")
        } else {
            return elem
        }
    }

    addCards(playingCards:PlayingCard[]) {
        for (let i = 0; i < playingCards.length; i++) {
            this.addCard(playingCards[i])
        }
    }
    
    addCard(playingCard:PlayingCard) {
        this.cards.push(playingCard)
    }

    cardAtIndex(index:number):PlayingCard {
        if (index >= this.cards.length || index < 0) {
            throw new Error("Out of Bounds Deck Index")
        }
        return this.cards[index]
    }

    getCount():number {
        return this.cards.length
    }

    getCards():PlayingCard[] {
        return this.cards
    }

    isEmpty():boolean {
        return this.cards.length === 0
    }

    // valueOf():number {
    //     let value = 0
    //     for (let i = 0; i < this.cards.length; i++) {
    //         value += this.cards[i].valueOf()
    //     }
    //     return value
    // }
}