import React, { CSSProperties } from 'react'
import ReactModal from 'react-modal'

interface ModalProps {
    isOpen: boolean,
    children?: React.ReactNode,
    buttonOnClick(): void,
    showX?: boolean
}

const contentProps:CSSProperties = {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    textAlign             : 'center',
    overflowY             : 'scroll',
    height                : '70%'
}

const Modal = ( props:ModalProps ) => {
    return (
        <ReactModal 
            isOpen={props.isOpen}
            style={{
                content : contentProps
            }}
        >
            <div style={{marginBottom: 15}}>
                {props.showX === false ? "" : <button 
                    onClick={()=>{ props.buttonOnClick() }}
                    style={{
                        top: 0,
                        right: 0,
                        position: "absolute",
                        fontSize: 20,
                        backgroundColor: "transparent",
                        outline: "none"
                    }}
                >
                    {` X `}
                </button>}
            </div>
            {props.children}
        </ReactModal>
    )
}

export default Modal