import React, { useState } from 'react'
import { Stack, Background, LobbyButton } from './styles'
import { Room, nullRoom, HistoryState, RoomAndPlayerUsername } from '../Types'
import { CreatePlayer, getRoom, RemovePlayer } from './utils'
import { useHistory } from 'react-router-dom'
import isObjEqual from 'fast-deep-equal'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistoryState } from '../StateContext'

let stopAlert = false

type doesRoomExistType = {
    status: number,
    room: Room
}

async function onSubmit(state:HistoryState, newGameId:any) {
    const username = state.username
    const oldGameId = state.gameId
    // Remove user from old room
    if (oldGameId !== "") {
        RemovePlayer(state.username, oldGameId)
    }
    let room = await getRoom(newGameId)
    if (room === nullRoom) {
        // Need to signal to user that the gameId was invalid
        alert(`You've entered an invalid gameId. Perhaps check your spelling? 
            ${newGameId} is what you entered`)
        stopAlert = true
        return ""
    }
    if (room.hasStarted === true) {
        alert("The game seems to have already started. You'll just have to wait or join another game.")
        return ""
    }
    return CreatePlayer(username, newGameId).then((obj:RoomAndPlayerUsername) => {
        return obj.username
    })
}

async function checkRoom(gameId:string):Promise<doesRoomExistType> {
    return getRoom(gameId).then(room => {
        if (!isObjEqual(room, nullRoom)) {
            return { status: 1, room:room }
        }
        return { status: -1, room:nullRoom }
    })
}

const Join = () => {
    let history = useHistory()

    const {state, setState} = useHistoryState()

    const [username, setUsername] = useState<string>(state.username)
    const [gameId, setGameId] = useState<string>(state.gameId)
    const [room, setRoom] = useState<Room>(nullRoom);

    // will be 0 if unchecked, 1 if checked and room exists, -1 if checked and room doesn't exist
    const [previousGameExists, setPreviousGameExists] = useState<number>(0)

    if (state.gameId !== "" && previousGameExists === 0) {
        checkRoom(state.gameId).then(doesRoomExist => {
            let status = doesRoomExist.status
            setPreviousGameExists(status)
            if (status === -1) {
                setState({username: state.username, gameId: ""})
            } else {
                setRoom(doesRoomExist.room)
            }
        })
        return (
            <Background>
                Loading ...
            </Background>
        )
    }

    if (previousGameExists === 1) {
        return (
            <Background>
                Do you want to join a new game or try to join the previous lobby/game you were in? You can only rejoin if your name is the same as before.
                If you were kicked before and want to try and join again, select "Join New Lobby".
                <br />
                <LobbyButton onClick={()=>{
                    RemovePlayer(state.username, state.gameId).then(() => {
                        setGameId("")
                        setPreviousGameExists(-1)
                        setState({username: state.username, gameId: ""})
                    })
                }}>
                    Join New Game
                </LobbyButton>
                <LobbyButton onClick={()=>{
                    stopAlert = false
                    setState({username: state.username, gameId: state.gameId})
                    if (room.hasStarted) {
                        history.push(`/start/${gameId}`)
                    } else {
                        history.push(`/lobby/${gameId}`)
                    }
                }}>
                    Join Old Lobby
                </LobbyButton>
            </Background>
        )
    } else {
        return (
            <Background>
                <ToastContainer />
                <Stack>
                    <input 
                        type="text"
                        placeholder={username === "" ? "Enter a Username": ""}
                        value={username}
                        onChange={(t) => {
                            setUsername(t.target.value)
                            setState({username: t.target.value, gameId: state.gameId})
                        }}/>
                    <div style={{ margin:10 }}/>
                    <span>
                        <input id="gameCodeInput" type="text" placeholder="Enter the game code" 
                            onChange={(t) => { setGameId(t.target.value) }}/>
                        <LobbyButton height={21} width="20%" onClick={() => {
                            if (username.length > 19) {
                                toast.error("Please enter a shorter name.")
                            } else {
                                onSubmit(state, gameId).then((uniqueUsername) => {
                                    if (uniqueUsername !== "") {
                                        setState({username: uniqueUsername, gameId: gameId})
                                        history.replace(`/lobby/${gameId}`)
                                    }
                                })
                            }
                        }} disabled={username === "" || gameId === ""}>
                            Submit
                        </LobbyButton>
                    </span>
                </Stack>
            </Background>
        )
    } 
}

export default Join