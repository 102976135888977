export enum Suit {
    Hearts,
    Spades,
    Diamonds,
    Clubs
}

// Ace is high
export enum CardName {
    Joker,
    Ace,
    Two,
    Three,
    Four,
    Five,
    Six,
    Seven,
    Eight,
    Nine,
    Ten,
    Jack,
    Queen,
    King
}

export class PlayingCard{
    cardName: CardName
    suit: Suit
    constructor (cardName:CardName, suit:Suit) {
        this.cardName = cardName
        this.suit = suit
    }

    valueOf() {
        return this.cardName+this.suit*31
    }
}

export const getFullFrenchDeck = ():PlayingCard[] => {
    let carddeck:PlayingCard[] = []
    for (let i = 1; i < 14; i++) {
        for (let j = 0; j < 4; j++) {
            carddeck.push(new PlayingCard(i,j))
        }
    }
    return carddeck
}

