import * as React from "react";
import {isOnMobile} from "./imageUtils";

function Svg3OfDiamonds(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <defs>
        <linearGradient id="3_of_diamonds_svg__a">
          <stop offset={0} />
          <stop offset={1} stopOpacity={0.649} />
        </linearGradient>
        <linearGradient id="3_of_diamonds_svg__b">
          <stop offset={0} stopColor="#fff" stopOpacity={0.435} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_diamonds_svg__c">
          <stop offset={0} stopColor="#fff" stopOpacity={0.489} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_diamonds_svg__d">
          <stop offset={0} stopColor="#fff" stopOpacity={0.237} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_diamonds_svg__e">
          <stop offset={0} stopColor="#fff" stopOpacity={0.29} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="3_of_diamonds_svg__g">
          <stop offset={0} stopColor="#df0000" />
          <stop offset={1} stopColor="#df0000" stopOpacity={0.641} />
        </linearGradient>
        <linearGradient id="3_of_diamonds_svg__f">
          <stop offset={0} stopColor="#fff" stopOpacity={0.435} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
      </defs>
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={7.846}
        y={26.413}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fill="#df0000"
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={7.846}
          y={26.413}

          fontFamily="Arial"
        >
          {"3"}
        </tspan>
      </text>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-159.488}
        y={-216.715}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fill="#df0000"
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-159.488}
          y={-216.715}

          fontFamily="Arial"
        >
          {"3"}
        </tspan>
      </text>
      <path
        d="M21.758 37.257c-3.126-4.23-4.79-8.528-4.79-8.528s-1.663 4.298-4.79 8.528c-3.128 4.229-7.025 6.98-7.025 6.98s3.897 2.748 7.025 6.98c3.127 4.228 4.79 8.527 4.79 8.527s1.664-4.3 4.79-8.527c3.128-4.232 7.025-6.98 7.025-6.98s-3.897-2.751-7.025-6.98zM155.411 191.525c-3.127-4.23-4.79-8.53-4.79-8.53s-1.664 4.3-4.79 8.53c-3.128 4.228-7.025 6.978-7.025 6.978s3.897 2.75 7.025 6.98c3.126 4.23 4.79 8.528 4.79 8.528s1.663-4.299 4.79-8.527c3.128-4.232 7.025-6.98 7.025-6.98s-3.897-2.751-7.025-6.98zM91.323 179.791c-5.479-7.412-8.394-14.946-8.394-14.946s-2.916 7.534-8.395 14.946c-5.482 7.41-12.312 12.23-12.312 12.23s6.83 4.819 12.312 12.234c5.48 7.41 8.395 14.944 8.395 14.944s2.915-7.534 8.394-14.944c5.482-7.415 12.312-12.233 12.312-12.233s-6.83-4.82-12.312-12.23zM91.323 38.589c-5.479-7.413-8.394-14.947-8.394-14.947s-2.916 7.534-8.395 14.947c-5.482 7.41-12.312 12.23-12.312 12.23s6.83 4.818 12.312 12.234c5.48 7.41 8.395 14.944 8.395 14.944s2.915-7.534 8.394-14.944c5.482-7.416 12.312-12.233 12.312-12.233S96.805 46 91.323 38.589zM91.323 108.29c-5.479-7.413-8.394-14.947-8.394-14.947s-2.916 7.534-8.395 14.947c-5.482 7.41-12.312 12.23-12.312 12.23s6.83 4.818 12.312 12.233c5.48 7.41 8.395 14.944 8.395 14.944s2.915-7.533 8.394-14.944c5.482-7.415 12.312-12.233 12.312-12.233s-6.83-4.82-12.312-12.23z"
        fill="#df0000"
      />
    </svg>
  );
}

export default Svg3OfDiamonds;
