import * as React from "react";
import {isOnMobile} from "./imageUtils";

function SvgAceOfHearts(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={isOnMobile() ? "49.4%" : "16%"}
      viewBox="0 0 167.087 242.667"
      {...props}
      preserveAspectRatio={"xMidYMid meet"}
    >
      <defs>
        <linearGradient id="ace_of_hearts_svg__a">
          <stop offset={0} />
          <stop offset={1} stopOpacity={0.649} />
        </linearGradient>
        <linearGradient id="ace_of_hearts_svg__b">
          <stop offset={0} stopColor="#fff" stopOpacity={0.435} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="ace_of_hearts_svg__c">
          <stop offset={0} stopColor="#fff" stopOpacity={0.489} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="ace_of_hearts_svg__d">
          <stop offset={0} stopColor="#fff" stopOpacity={0.237} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="ace_of_hearts_svg__e">
          <stop offset={0} stopColor="#df0000" />
          <stop offset={1} stopColor="#df0000" stopOpacity={0.672} />
        </linearGradient>
        <linearGradient id="ace_of_hearts_svg__f">
          <stop offset={0} stopColor="#fff" stopOpacity={0.313} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="ace_of_hearts_svg__g">
          <stop offset={0} stopColor="#fff" stopOpacity={0.29} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <radialGradient
          xlinkHref="#ace_of_hearts_svg__e"
          id="ace_of_hearts_svg__h"
          cx={-0.206}
          cy={-4.579}
          fx={-0.206}
          fy={-4.579}
          r={8}
          gradientTransform="matrix(-1 0 0 -1.72018 -.412 -13.027)"
          gradientUnits="userSpaceOnUse"
        />
        <radialGradient
          r={81.903}
          fy={511.223}
          fx={171.487}
          cy={511.223}
          cx={171.487}
          gradientTransform="matrix(1.15299 -.67392 .39482 .67549 -233.633 270.4)"
          gradientUnits="userSpaceOnUse"
          id="ace_of_hearts_svg__i"
          xlinkHref="#ace_of_hearts_svg__f"
        />
        <radialGradient
          r={81.903}
          fy={492.632}
          fx={159.354}
          cy={492.632}
          cx={159.354}
          gradientTransform="matrix(1.08948 -.71514 .44645 .65627 -244.933 290.918)"
          gradientUnits="userSpaceOnUse"
          id="ace_of_hearts_svg__k"
          xlinkHref="#ace_of_hearts_svg__g"
        />
        <filter
          colorInterpolationFilters="sRGB"
          id="ace_of_hearts_svg__j"
          x={-0.139}
          width={1.279}
          y={-0.162}
          height={1.325}
        >
          <feGaussianBlur stdDeviation={9.511} />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          id="ace_of_hearts_svg__l"
          x={-0.139}
          width={1.279}
          y={-0.162}
          height={1.325}
        >
          <feGaussianBlur stdDeviation={9.511} />
        </filter>
      </defs>
      <g stroke="#000">
        <path
          d="M166.837 235.548c0 3.777-3.087 6.869-6.871 6.869H7.11c-3.775 0-6.861-3.092-6.861-6.87V7.12C.25 3.343 3.336.25 7.11.25h152.856c3.784 0 6.87 3.093 6.87 6.87v228.428z"
          fill="#fff"
          strokeWidth={0.5}
        />
      </g>
      <text
        style={{
          lineHeight: "125%",
        }}
        x={6.246}
        y={28.013}
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fill="#df0000"
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={6.246}
          y={28.013}

          fontFamily="Arial"
        >
          {"A"}
        </tspan>
      </text>
      <path
        d="M3.676-9C.433-9 0-5.523 0-5.523S-.433-9-3.676-9C-5.946-9-8-7.441-8-4.5-8-.614-1.42 3.294 0 9 1.352 3.299 8-.614 8-4.5 8-7.441 5.946-9 3.676-9z"
        fill="url(#ace_of_hearts_svg__h)"
        transform="matrix(6.8256 0 0 6.38811 83.142 122.273)"
      />
      <path
        transform="matrix(.2922 0 0 .3026 -4.335 -71.839)"
        d="M117.301 604.266s-8.067-94.95 22.857-122.857c34.761-31.369 140-11.429 140-11.429s-71.54 24.838-100 48.572c-27.21 22.692-62.857 85.714-62.857 85.714z"
        fill="url(#ace_of_hearts_svg__i)"
        filter="url(#ace_of_hearts_svg__j)"
      />
      <path
        transform="matrix(.22705 .0665 -.06483 .27594 94.814 -72.097)"
        d="M117.301 604.266s-8.067-94.95 22.857-122.857c34.761-31.369 140-11.429 140-11.429s-63.093 37.07-91.552 60.805c-27.21 22.692-71.305 73.481-71.305 73.481z"
        fill="url(#ace_of_hearts_svg__k)"
        filter="url(#ace_of_hearts_svg__l)"
      />
      <path
        d="M23.332 32.282c-5.43 0-6.154 5.449-6.154 5.449s-.725-5.449-6.155-5.449c-3.8 0-7.24 2.443-7.24 7.052 0 6.09 11.016 12.213 13.395 21.154 2.263-8.934 13.394-15.065 13.394-21.154 0-4.609-3.44-7.052-7.24-7.052z"
        fill="#df0000"
      />
      <text
        style={{
          lineHeight: "125%",
        }}
        x={-161.088}
        y={-213.515}
        transform="scale(-1)"
        fontSize={32}
        fontWeight={400}
        letterSpacing={0}
        wordSpacing={0}
        fill="#df0000"
        fontFamily="Bitstream Vera Sans"
      >
        <tspan
          x={-161.088}
          y={-213.515}

          fontFamily="Arial"
        >
          {"A"}
        </tspan>
      </text>
      <g fill="#df0000">
        <path d="M144.001 209.246c5.43 0 6.155-5.448 6.155-5.448s.725 5.448 6.155 5.448c3.8 0 7.24-2.443 7.24-7.051 0-6.09-11.016-12.213-13.395-21.155-2.264 8.934-13.394 15.065-13.394 21.155 0 4.608 3.439 7.051 7.24 7.051z" />
      </g>
    </svg>
  );
}

export default SvgAceOfHearts;
